import { Controller, useFormContext } from 'react-hook-form';

/* eslint-disable react/jsx-props-no-spreading */
import { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';
import { DefaultTFuncReturn } from 'i18next';
import { Input } from '@chakra-ui/react';

interface AssessmentCreateInputProps {
  name: any;
  placeholder: string | DefaultTFuncReturn;
  onHandleChange: (value: string) => void;
}

export default function AssessmentCreateInput({
  name,
  placeholder,
  onHandleChange,
}: AssessmentCreateInputProps) {
  const { control } = useFormContext<AssessmentFormData>();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          height={12}
          variant="create"
          {...field}
          placeholder={placeholder as string}
          onChange={(e) => {
            onHandleChange(e.target.value);
            field.onChange(e);
          }}
          value={field.value || ''}
        />
      )}
    />
  );
}
