/* eslint-disable react/jsx-props-no-spreading */
import ContentFormTextGroup from '@/client/components/admin/create/content/text/ContentFormTextGroup';
import TinyMceEditor from '@/client/components/admin/create/shared/TinyMceEditor';
import DraggableChoiceOptionList from './DraggableChoiceOptionList';
import ResizeTextarea from 'react-textarea-autosize';
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  OrderedList,
  Spinner,
  Stack,
  Text,
  Textarea,
  TextareaProps,
  useToken,
  VStack,
  FormControl,
} from '@chakra-ui/react';
import {
  Check,
  CircleCheck,
  DragHandle,
  Dropdown,
  LongAnswer,
  Media,
  MultipleChoice,
  Randomize,
  ShortAnswer,
  Trash,
  Plus,
  MultipleChoiceSimple,
  DropdownSimple,
  ShortAnswerSimple,
  LongAnswerSimple,
} from '@/client/components/icons/ContinuIcons';
import { ChevronDownIcon } from '@chakra-ui/icons';
import type { QuestionData } from '@/client/services/api/graphql/gql/graphql';

import AssessmentCounter from '@/client/components/admin/create/content/input/AssessmentCounter';
import type { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';

import { useAssessmentStore } from '@/client/services/state/admin/create/assessmentStore';
import { useEffect, useState, useRef, Fragment, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import invariant from 'tiny-invariant';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { getQuestionData, isQuestionData } from './TextResponseQuestionBuilder';
import { useToastStore } from '@/client/services/state/toastStore';
import AuthoringImageEditor from '../../../../images/AuthoringImageEditor';
import AuthoringImageActionsButtonGroup from '@/client/components/admin/create/content/buttons/AuthoringImageActionsButtonGroup';
import { useMutation } from '@tanstack/react-query';
import AuthoringVideoService from '@/client/services/api/admin/content-authoring/AuthoringVideoService';
import VideoPlayer from '@/client/components/media/video/VideoPlayer';
import { RxCross1 } from 'react-icons/rx';
import { makeId } from '@/client/utils/admin/create/makeId';

type SectionState =
  | {
      type: 'idle';
    }
  | {
      type: 'preview';
      container: HTMLElement;
    }
  | {
      type: 'is-dragging';
    }
  | {
      type: 'is-dragging-over';
      closestEdge: Edge | null;
    };

const idle: SectionState = { type: 'idle' };

const AutoResizeTextarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => (
  <Textarea
    minH="unset"
    overflow="hidden"
    w="100%"
    resize="none"
    ref={ref}
    minRows={1}
    as={ResizeTextarea}
    {...props}
  />
));

interface DiscardButtonProps {
  onClick: () => void;
}

function DiscardButton({ onClick }: DiscardButtonProps) {
  return (
    <Button
      aria-label="Close"
      size="sm"
      width="40px"
      height="40px"
      variant="ghost"
      color="neutral.1000"
      position="absolute"
      top={4}
      right={4}
      bg="white"
      _hover={{ bg: 'neutral.200' }}
      rounded="full"
      border="1px solid"
      borderColor="neutral.100"
      onClick={onClick}
      zIndex="1"
      pointerEvents="auto"
    >
      <RxCross1 />
    </Button>
  );
}

interface TextResponseQuestionProps {
  question: QuestionData;
  validateCurrentEditQuestion: () => boolean;
  isFirst: boolean;
}

export default function TextResponseQuestion({
  question,
  validateCurrentEditQuestion,
  isFirst,
}: TextResponseQuestionProps) {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext<AssessmentFormData>();
  const [borderColor, neutralBorderColor] = useToken('colors', ['warmNeutral.500', 'neutral.200']);
  const { selectedEditQuestion, setSelectedEditQuestion, uploadStatus, setUploadStatus } =
    useAssessmentStore();
  const { setToast } = useToastStore();

  const [state, setState] = useState<SectionState>(idle);
  const [image, setImage] = useState<string>('');
  const [renderImageEditActions, setRenderImageEditActions] = useState<boolean>(false);
  const [, setVideoUpload] = useState(0);
  const [discarded, setDiscarded] = useState<boolean>(false);

  const mediaRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const questionInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isFirst) {
      questionInputRef.current?.focus();
    }
  }, [isFirst]);

  const questionTypeOptions = [
    {
      value: 'multiple_choice',
      label: t('assessment.textResponse.questionFormat.multipleChoice'),
      icon: MultipleChoice,
      previewIcon: MultipleChoiceSimple,
    },
    {
      value: 'dropdown',
      label: t('assessment.textResponse.questionFormat.dropdown'),
      icon: Dropdown,
      previewIcon: DropdownSimple,
    },
    {
      value: 'short_input',
      label: t('assessment.textResponse.questionFormat.shortAnswer'),
      icon: ShortAnswer,
      previewIcon: ShortAnswerSimple,
    },
    {
      value: 'long_input',
      label: t('assessment.textResponse.questionFormat.longAnswer'),
      icon: LongAnswer,
      previewIcon: LongAnswerSimple,
    },
  ];

  const isSelectEditQuestion = selectedEditQuestion?.id === question.id;

  const currentBorderColor = isSelectEditQuestion ? borderColor : neutralBorderColor;

  const { questions } = watch();

  const questionIndex = questions.findIndex((q) => q.id === question.id);

  const questionValue = watch(`questions.${questionIndex}.question`);

  const manualPointsIndex = question?.questionOptions?.findIndex(
    (option) => option.name === 'manual_points',
  );

  const minWordsIndex = question?.questionOptions?.findIndex(
    (option) => option.name === 'word_minimum',
  );

  const maxWordsIndex = question?.questionOptions?.findIndex(
    (option) => option.name === 'word_limit',
  );

  const graderInstructionsIndex = question?.questionOptions?.findIndex(
    (data) => data.name === 'grader_instructions',
  );

  const useManualGrade =
    ((question?.questionOptions?.find((option) => option.name === 'manual_grade')
      ?.value as boolean) &&
      question.format === 'short_input') ??
    false;

  const questionUsesChoiceOptionBuilder =
    question.format === 'multiple_choice' ||
    question.format === 'dropdown' ||
    (question.format === 'short_input' && !useManualGrade);

  const renderGraderInstructions =
    !questionUsesChoiceOptionBuilder || (question.format === 'short_input' && useManualGrade);

  const totalManualPoints = question?.questionOptions?.find(
    (option) => option.name === 'manual_points',
  )?.value as number;

  const validChoiceOptions = question?.choiceOptions?.filter((option) => option.points !== 0);

  const numberOfValidChoiceOptions = question?.choiceOptions?.filter(
    (option) => option.points !== 0,
  ).length;

  const selectedNumberOfSelections =
    (question?.questionOptions?.find((option) => option.name === 'number_of_selections')
      ?.value as number) || 1;

  const renderMultipleSelectionMenu =
    (question.format === 'multiple_choice' || question.format === 'short_input') &&
    numberOfValidChoiceOptions! > 1;

  const renderRandomizeChoiceOrderButton =
    question.format === 'multiple_choice' || question.format === 'dropdown';

  const randomizeChoiceOrder: boolean = question?.questionOptions?.find(
    (option) => option.name === 'randomize_choice_order',
  )?.value as boolean;

  const questionMedia = watch(`questions.${questionIndex}.questionAsset`);

  const questionMediaType = watch(`questions.${questionIndex}.questionAssetType`);

  const handleRandomize = (value: boolean) => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === question.id) {
        const checkRandomize = q?.questionOptions?.some(
          (option) => option.name === 'randomize_choice_order',
        );

        if (!checkRandomize) {
          return {
            ...q,
            questionOptions: [
              ...(q.questionOptions ?? []),
              { name: 'randomize_choice_order', value },
            ],
          };
        }
        return {
          ...q,
          questionOptions: q?.questionOptions?.map((option) => {
            if (option.name === 'randomize_choice_order') {
              return {
                ...option,
                value,
                __typename: option.__typename, // Ensure __typename is preserved
              };
            }

            return option;
          }) as QuestionData['questionOptions'], // Type assertion to ensure compatibility
        };
      }

      return q;
    });

    setValue('questions', updatedQuestions);
  };

  const handleSetQuestionFormat = (value: string) => {
    clearErrors();

    const updatedQuestions = questions.map((q) => {
      if (q.id === question.id) {
        return {
          ...q,
          choiceOptions: q.choiceOptions?.map((option) => ({
            ...option,
            points: value === 'short_input' ? 1 : 0,
          })),
          format: value,
          __typename: q.__typename, // Ensure __typename is preserved
        };
      }

      return q;
    });

    setValue('questions', updatedQuestions);
  };

  const handleRemoveQuestion = (questionToRemove: QuestionData) => {
    const updatedQuestions = questions.filter((q) => q.id !== questionToRemove.id);

    setValue('questions', updatedQuestions);
  };

  const handleSetNumberOfRequiredSelections = (value: number) => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === question.id) {
        return {
          ...q,
          questionOptions: q?.questionOptions?.map((option) => {
            if (option.name === 'number_of_selections') {
              return {
                ...option,
                value,
                __typename: option.__typename, // Ensure __typename is preserved
              };
            }

            if (option.name === 'multiple_selections') {
              return {
                ...option,
                value: value > 1,
                __typename: option.__typename, // Ensure __typename is preserved
              };
            }

            return option;
          }) as QuestionData['questionOptions'], // Type assertion to ensure compatibility
        };
      }

      return q;
    });

    setValue('questions', updatedQuestions);
  };

  const handleSetManualGrade = (value: boolean) => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === question.id) {
        return {
          ...q,
          questionOptions: q?.questionOptions?.map((option) => {
            if (option.name === 'manual_grade') {
              return {
                ...option,
                value,
                __typename: option.__typename, // Ensure __typename is preserved
              };
            }

            return option;
          }) as QuestionData['questionOptions'], // Type assertion to ensure compatibility
        };
      }

      return q;
    });

    setValue('questions', updatedQuestions);
  };

  const uploadQuestionVideoMutation = useMutation({
    mutationFn: (file: File) => AuthoringVideoService.uploadVideo(file, setVideoUpload),
    onSuccess: (data) => {
      if (discarded) {
        setDiscarded(false);
        return;
      }

      const { cloudFrontLink } = data;

      setValue(`questions.${questionIndex}.questionAsset`, cloudFrontLink);
      setValue(`questions.${questionIndex}.questionAssetType`, 'video');
    },
  });

  const { status } = uploadQuestionVideoMutation;

  const handleUploadClick = () => (mediaRef.current ? mediaRef.current.click() : null);

  const videoMimeTypes = ['video/mp4'];

  const imageMimeTypes = ['image/webp', 'image/png', 'image/jpeg'];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;

    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({
        show: true,
        status: 'error',
        title: t('assessment.textResponse.mediaUpload.error'),
      });
      return;
    }

    const isImage = imageMimeTypes.includes(fileObject.type);

    const isVideo = videoMimeTypes.includes(fileObject.type);

    if (!isImage && isVideo && fileObject.size > 2048000000) {
      setToast({
        show: true,
        status: 'error',
        title: t('edit.videos.1024_limit'),
      });

      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    if (isImage) {
      setImage(URL.createObjectURL(fileObject));
    } else {
      uploadQuestionVideoMutation.mutateAsync(fileObject);
    }
  };

  const handleSetSelectedEditQuestion = (editQuestion: QuestionData) => {
    if (editQuestion.id === selectedEditQuestion?.id) return;

    const isValid = validateCurrentEditQuestion();

    if (!isValid) return;

    setSelectedEditQuestion(editQuestion);
  };

  useEffect(() => {
    setUploadStatus(status);
  }, [status]);

  useEffect(() => {
    if (numberOfValidChoiceOptions! < selectedNumberOfSelections) {
      handleSetNumberOfRequiredSelections(numberOfValidChoiceOptions!);
    }
  }, [numberOfValidChoiceOptions]);

  useEffect(() => {
    if (question.format === 'short_input') {
      return;
    }

    if (question.format === 'long_input') {
      handleSetManualGrade(true);

      return;
    }

    handleSetManualGrade(false);
  }, [question.format]);

  useEffect(() => {
    const element = ref.current;
    invariant(element);
    return combine(
      draggable({
        element,
        getInitialData() {
          return getQuestionData(question);
        },
        onDragStart() {
          setState({ type: 'is-dragging' });
        },
        onDrop() {
          setState(idle);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          // * Don't allow items to drop on themselves
          if (source.element === element) {
            return false;
          }
          // * Only allow course section data to be dropped
          return isQuestionData(source.data);
        },
        getData({ input }) {
          const data = getQuestionData(question);
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ['top', 'bottom'],
          });
        },
        getIsSticky() {
          return true;
        },
        onDragEnter({ self }) {
          const closestEdge = extractClosestEdge(self.data);
          setState({ type: 'is-dragging-over', closestEdge });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          // * Only need to update react state if nothing has changed.
          // * Prevents re-rendering.
          setState((current) => {
            if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
              return current;
            }

            return { type: 'is-dragging-over', closestEdge };
          });
        },
        onDragLeave() {
          setState(idle);
        },
        onDrop() {
          setState(idle);
        },
      }),
    );
  }, [question]);

  const handleRemovePageBreak = (id: string) =>
    setValue(
      'questions',
      questions.filter((q: QuestionData) => q.id !== id),
    );

  const calculateTotalPoints = () => {
    const validOptions = question?.choiceOptions
      ?.filter((option) => option.points !== 0)
      .sort((a, b) => b.points! - a.points!)
      .slice(0, selectedNumberOfSelections);

    return validOptions?.reduce((acc, option) => acc + option.points!, 0) ?? 0;
  };

  const handleAddChoiceOption = () => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === question.id) {
        return {
          ...q,
          choiceOptions: [
            ...(q.choiceOptions ?? []),
            {
              id: makeId(),
              copy: '',
              image: null,
              points: question.format === 'short_input' ? 1 : 0,
              position: q?.choiceOptions?.length,
            },
          ],
        };
      }

      return q;
    });

    setValue('questions', updatedQuestions);
  };

  if (question.format === 'page_break') {
    return (
      <HStack ref={ref} key={question.id}>
        <Divider borderColor="neutral.500" marginRight={6} />

        <Icon as={DragHandle} boxSize={4} _hover={{ cursor: 'grab' }} />

        <Icon
          as={Trash}
          boxSize={4}
          _hover={{ cursor: 'pointer' }}
          onClick={() => handleRemovePageBreak(question.id!)}
        />
      </HStack>
    );
  }

  return (
    <Box
      ref={ref}
      position="relative"
      border="1px solid"
      backgroundColor="white"
      borderRadius={24}
      paddingY={8}
      paddingX={12}
      boxShadow={isSelectEditQuestion ? '0px 4px 12px 0px rgba(0, 0, 0, 0.12)' : 'none'}
      _hover={{
        cursor: 'pointer',
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
        transition: 'box-shadow 300ms ease',
      }}
      onClick={() => handleSetSelectedEditQuestion(question)}
      sx={{
        opacity: state.type === 'is-dragging' ? 0.4 : 1,
        border: `1px solid ${currentBorderColor}`,
        // borderTop:
        //   state.type === 'is-dragging-over' && state.closestEdge === 'top'
        //     ? `1px solid ${borderHoverColor}`
        //     : `1px solid ${currentBorderColor}`,
        marginTop: state.type === 'is-dragging-over' && state.closestEdge === 'top' ? 44 : 0,
        // borderBottom:
        //   state.type === 'is-dragging-over' && state.closestEdge === 'bottom'
        //     ? `1px solid ${borderHoverColor}`
        //     : `1px solid ${currentBorderColor}`,
        marginBottom: state.type === 'is-dragging-over' && state.closestEdge === 'bottom' ? 44 : 0,
      }}
    >
      <Flex
        direction="column"
        borderBottom="1px solid"
        borderBottomColor="neutral.100"
        paddingBottom={6}
        marginBottom={8}
      >
        {!isSelectEditQuestion && (
          <Flex marginBottom={4} width="full" justifyContent="space-between">
            <Flex
              alignItems="center"
              alignContent="center"
              backgroundColor="warmNeutral.200"
              borderRadius="full"
              paddingX={3}
              paddingY={1}
            >
              <Icon
                as={
                  questionTypeOptions.find((option) => option.value === question.format)
                    ?.previewIcon
                }
                color="warmNeutral.1200"
                boxSize={4}
                marginRight={2}
              />

              <Text fontSize="12px" fontWeight="semibold" color="warmNeutral.1200">
                {questionTypeOptions.find((option) => option.value === question.format)?.label}
              </Text>
            </Flex>

            <HStack>
              <Icon as={DragHandle} boxSize={4} _hover={{ cursor: 'grab' }} />

              {questions.length > 1 && (
                <Icon
                  as={Trash}
                  boxSize={4}
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => handleRemoveQuestion(question)}
                />
              )}
            </HStack>
          </Flex>
        )}

        <Flex justifyContent="space-between" alignItems="flex-start" direction="row">
          <Box flex={1} marginRight={10}>
            {isSelectEditQuestion && (
              <FormControl
                isInvalid={!!errors.questions && !!errors.questions[questionIndex]?.question}
              >
                {/** Leaving commented code temporarily for reference */}

                {/* <Textarea
                  {...register(`questions.${questionIndex}.question`, { required: true })}
                  onChange={(e) => setValue(`questions.${questionIndex}.question`, e.target.value)}
                  resize="none"
                  placeholder={`${t('assessment.textResponse.addQuestion.placeholder')}`}
                  fontSize="16px"
                  fontWeight={600}
                  border="none"
                  _placeholder={{ color: 'neutral.600', fontSize: '16px', fontWeight: 600 }}
                  _focusVisible={{ border: 'none' }}
                  value={questionValue as string}
                  height="auto"
                  minHeight="120px"
                  overflowY="auto"
                  p={2}
                  paddingLeft={0}
                /> */}

                <AutoResizeTextarea
                  {...register(`questions.${questionIndex}.question`, { required: true })}
                  onChange={(e) => setValue(`questions.${questionIndex}.question`, e.target.value)}
                  placeholder={`${t('assessment.textResponse.addQuestion.placeholder')}`}
                  fontSize="16px"
                  fontWeight={600}
                  border="none"
                  _placeholder={{ color: 'neutral.600', fontSize: '16px', fontWeight: 600 }}
                  _focusVisible={{ border: 'none' }}
                  value={questionValue as string}
                />
              </FormControl>
            )}

            {!isSelectEditQuestion && (
              <Text fontSize="16px" fontWeight={600} wordBreak="break-word">
                {question.question || 'Untitled Question'}
              </Text>
            )}
          </Box>

          <HStack spacing={4} align={!isSelectEditQuestion ? 'start' : 'center'}>
            {isSelectEditQuestion && (
              <Menu>
                <MenuButton
                  as={Button}
                  leftIcon={
                    <Icon
                      as={
                        questionTypeOptions.find((option) => option.value === question.format)?.icon
                      }
                      boxSize={6}
                    />
                  }
                  rightIcon={<ChevronDownIcon color="baseBlack" boxSize={6} />}
                  backgroundColor="white"
                  border="1px solid"
                  borderColor="warmNeutral.200"
                  color="baseBlack"
                  fontSize="14px"
                  fontWeight={500}
                  _hover={{
                    backgroundColor: 'warmNeutral.0',
                  }}
                  _active={{
                    backgroundColor: 'warmNeutral.0',
                  }}
                >
                  {questionTypeOptions.find((option) => option.value === question.format)?.label}
                </MenuButton>

                <MenuList borderColor="warmNeutral.200" borderRadius="6px">
                  {questionTypeOptions.map((option, index) => (
                    <>
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        fontSize="14px"
                        fontWeight={500}
                        _hover={{
                          backgroundColor: 'warmNeutral.0',
                        }}
                        onClick={() => handleSetQuestionFormat(option.value)}
                      >
                        <HStack alignContent="center" justifyContent="center" alignItems="center">
                          <Icon
                            as={option.value === question.format ? Check : option.icon}
                            boxSize={option.value === question.format ? 3 : 6}
                          />

                          <Text>{option.label}</Text>
                        </HStack>
                      </MenuItem>

                      {index !== questionTypeOptions.length - 1 && <MenuDivider />}
                    </>
                  ))}
                </MenuList>
              </Menu>
            )}

            {!isSelectEditQuestion && questionMedia && questionMediaType === 'image' && (
              <HStack>
                <Image
                  rounded="md"
                  src={questionMedia as string}
                  maxHeight="150px"
                  maxWidth="200px"
                />
              </HStack>
            )}

            {!isSelectEditQuestion && questionMedia && questionMediaType === 'video' && (
              <Box height="150px" width="200px">
                <HStack>
                  <VideoPlayer
                    url={questionMedia as string}
                    videoSource="custom"
                    setRenderRecommendations={() => {}}
                  />
                </HStack>
              </Box>
            )}

            {isSelectEditQuestion && (
              <HStack>
                <Icon as={DragHandle} boxSize={4} _hover={{ cursor: 'grab' }} />

                {questions.length > 1 && (
                  <Icon
                    as={Trash}
                    boxSize={4}
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveQuestion(question)}
                  />
                )}
              </HStack>
            )}
          </HStack>
        </Flex>

        {isSelectEditQuestion && questionMedia && questionMediaType === 'image' && (
          <Box
            height={400}
            width="auto"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundImage={questionMedia}
            backgroundPosition="center center"
            borderRadius={10}
            marginTop={6}
          >
            <Center
              height="full"
              borderRadius={6}
              backgroundColor="blackAlpha.50"
              onMouseEnter={() => setRenderImageEditActions(true)}
              onMouseLeave={() => setRenderImageEditActions(false)}
            >
              {renderImageEditActions && (
                <AuthoringImageActionsButtonGroup
                  onRemove={() => {
                    setValue(`questions.${questionIndex}.questionAssetType`, '');
                    setValue(`questions.${questionIndex}.questionAsset`, '');
                  }}
                  onEdit={() => setImage(questionMedia)}
                />
              )}
            </Center>
          </Box>
        )}

        {isSelectEditQuestion && (uploadStatus === 'loading' || discarded) && (
          <Box
            height={400}
            width="auto"
            backgroundColor="neutral.50"
            borderRadius={10}
            marginTop={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <DiscardButton
              onClick={() => {
                setDiscarded(true);
                setVideoUpload(0);
              }}
            />
            <VStack spacing={4}>
              <Spinner size="sm" mt={4} color="neutral.200" />

              <Text fontSize="16px" fontWeight={600}>
                {discarded
                  ? t('assessment.textResponse.mediaUpload.cancel')
                  : t('asssessment.textResponse.mediaUpload.processing')}
              </Text>
            </VStack>
          </Box>
        )}

        {isSelectEditQuestion && questionMedia && questionMediaType === 'video' && (
          <Box marginTop={4} position="relative">
            <VideoPlayer
              url={questionMedia as string}
              videoSource="custom"
              setRenderRecommendations={() => {}}
            />

            <DiscardButton
              onClick={() => {
                setValue(`questions.${questionIndex}.questionAssetType`, '');
                setValue(`questions.${questionIndex}.questionAsset`, '');
                setUploadStatus('idle');
              }}
            />
          </Box>
        )}
      </Flex>

      {isSelectEditQuestion && (
        <>
          <HStack spacing={6} marginBottom={6}>
            {question.format === 'long_input' && (
              <HStack paddingBottom={10} width="30%">
                <Stack>
                  <Text variant="createLabel">Min Words</Text>

                  <Input
                    {...(minWordsIndex !== undefined &&
                      register(
                        `questions.${questionIndex}.questionOptions.${minWordsIndex}.value`,
                      ))}
                    type="number"
                    size="lg"
                    placeholder="0"
                    fontWeight={500}
                    variant="create"
                    _placeholder={{
                      fontWeight: 500,
                      color: 'neutral.600',
                    }}
                  />
                </Stack>

                <Divider marginTop={6} borderWidth="2px" borderColor="warmNeutral.1000" width={4} />

                <Stack>
                  <Text variant="createLabel">Max Words</Text>

                  <Input
                    {...(maxWordsIndex !== undefined &&
                      register(
                        `questions.${questionIndex}.questionOptions.${maxWordsIndex}.value`,
                      ))}
                    type="number"
                    placeholder="5000"
                    size="lg"
                    fontWeight={500}
                    variant="create"
                    _placeholder={{
                      fontWeight: 500,
                      color: 'neutral.600',
                    }}
                  />
                </Stack>
              </HStack>
            )}

            <ButtonGroup paddingLeft={0}>
              <Input
                display="none"
                ref={mediaRef}
                type="file"
                accept="image/png, image/jpeg, image/webp, image/jpg,  video/*"
                onChange={(e) => handleFileChange(e)}
              />

              <Button
                variant="createNeutral"
                marginStart="0 !important"
                leftIcon={<Media color="neutral.1200" boxSize={4} />}
                onClick={handleUploadClick}
                isDisabled={
                  (questionMedia != null && questionMedia !== '') || uploadStatus === 'loading'
                }
              >
                {t('assessment.textResponse.addMedia')}
              </Button>

              {renderRandomizeChoiceOrderButton && (
                <Button
                  variant="createNeutral"
                  leftIcon={
                    randomizeChoiceOrder ? (
                      <CircleCheck color="neutral.1200" boxSize={4} />
                    ) : (
                      <Randomize color="neutral.1200" boxSize={4} />
                    )
                  }
                  onClick={() => handleRandomize(!randomizeChoiceOrder)}
                >
                  {t('assessment.textResponse.randomizeAnswers')}
                </Button>
              )}
            </ButtonGroup>

            <AuthoringImageEditor
              image={image}
              setImage={setImage}
              type="assessmentQuestion"
              setFormValue={(cloudfrontLink: string) => {
                setValue(`questions.${questionIndex}.questionAsset`, cloudfrontLink);
                setValue(`questions.${questionIndex}.questionAssetType`, 'image');
              }}
            />

            {question.format === 'short_input' && (
              <Checkbox
                variant="assessment"
                isChecked={useManualGrade}
                onChange={() => handleSetManualGrade(!useManualGrade)}
              >
                {t('assessment.gradeResponsesManually')}
              </Checkbox>
            )}
          </HStack>

          {question.format === 'short_input' && !useManualGrade && (
            <Text fontSize="16px" fontWeight={600} color="baseBlack" marginBottom={8}>
              {t('assessment.acceptableShortAnswers')}
            </Text>
          )}

          {renderGraderInstructions && (
            <Stack marginY={8} spacing={6}>
              <ContentFormTextGroup label={t('assessment.textResponse.graderInstructions')} />

              <TinyMceEditor
                isRequired={false}
                formName={`questions.${questionIndex}.questionOptions.${graderInstructionsIndex}.value`}
                placeholder={t('authoring.assessment.grading.graderInstructions.placeholder')}
                label=""
                toolbarOpts={false}
                useMenu={false}
                minHeight={50}
              />
            </Stack>
          )}

          {question.format === 'short_input' && useManualGrade && (
            <Stack>
              <AssessmentCounter
                name={`questions.${questionIndex}.questionOptions.${manualPointsIndex}.value`}
                counterMin={1}
                counterMax={99}
                label={t('authoring.assessment.grading.points')}
                option="manual_points"
                buttonSize="sm"
                inputWidth={14}
                onHandleChange={(val) => {
                  if (manualPointsIndex !== undefined) {
                    setValue(
                      `questions.${questionIndex}.questionOptions.${manualPointsIndex}.value`,
                      val,
                    );
                  }
                }}
              />
            </Stack>
          )}

          {questionUsesChoiceOptionBuilder && !useManualGrade && (
            <DraggableChoiceOptionList question={question} />
          )}

          <Flex marginTop={6} justifyContent="space-between">
            {questionUsesChoiceOptionBuilder && !useManualGrade && (
              <Button
                variant="createNeutral"
                leftIcon={<Icon as={Plus} />}
                onClick={handleAddChoiceOption}
                paddingX={5}
              >
                {t('assessment.textResponse.addAnswer.label')}
              </Button>
            )}

            <Flex alignItems="center" width="full" justifyContent="flex-end" fontSize="16px">
              <HStack spacing={6}>
                {isSelectEditQuestion && (
                  <>
                    {renderMultipleSelectionMenu && (
                      <Menu>
                        <MenuButton
                          as={Button}
                          rightIcon={<ChevronDownIcon color="baseBlack" boxSize={6} />}
                          backgroundColor="white"
                          border="1px solid"
                          borderColor="warmNeutral.200"
                          color="baseBlack"
                          fontSize="14px"
                          fontWeight={500}
                          _hover={{
                            backgroundColor: 'warmNeutral.0',
                          }}
                          _active={{
                            backgroundColor: 'warmNeutral.0',
                          }}
                        >
                          {`${selectedNumberOfSelections} Answer${
                            selectedNumberOfSelections > 1 ? 's' : ''
                          } Required`}
                        </MenuButton>

                        <MenuList borderColor="warmNeutral.200" borderRadius="6px">
                          {validChoiceOptions?.map((option, index) => (
                            <Fragment key={option.id}>
                              <MenuItem
                                key={option.id}
                                value={index + 1}
                                fontSize="14px"
                                fontWeight={500}
                                _hover={{
                                  backgroundColor: 'warmNeutral.0',
                                }}
                                onClick={() => handleSetNumberOfRequiredSelections(index + 1)}
                              >
                                <Text>{index + 1}</Text>
                              </MenuItem>

                              {index !== validChoiceOptions.length - 1 && <MenuDivider />}
                            </Fragment>
                          ))}
                        </MenuList>
                      </Menu>
                    )}

                    {!questionUsesChoiceOptionBuilder && (
                      <AssessmentCounter
                        name={`questions.${questionIndex}.questionOptions.${manualPointsIndex}.value`}
                        counterMin={1}
                        counterMax={99}
                        label={t('authoring.assessment.grading.points')}
                        option="manual_points"
                        buttonSize="sm"
                        inputWidth={14}
                        onHandleChange={() => {}}
                      />
                    )}
                  </>
                )}

                <Box>
                  {t('assessment.textResponse.totalPointsPossible')}

                  <Text as="span" fontWeight={700} marginLeft={2}>
                    {questionUsesChoiceOptionBuilder && !useManualGrade
                      ? calculateTotalPoints()
                      : totalManualPoints}
                  </Text>
                </Box>
              </HStack>
            </Flex>
          </Flex>
        </>
      )}

      {!isSelectEditQuestion && questionUsesChoiceOptionBuilder && (
        <OrderedList styleType="upper-alpha" marginLeft={20}>
          {question?.choiceOptions?.map((choiceOption, index) => (
            <Flex key={choiceOption.id} position="relative" alignItems="center">
              {choiceOption.points !== 0 && (
                <Check color="warmNeutral.500" position="absolute" left={-50} />
              )}

              <ListItem>
                <HStack>
                  <Text>{choiceOption.copy || `Answer ${index + 1}`}</Text>
                </HStack>
              </ListItem>
            </Flex>
          ))}
        </OrderedList>
      )}
    </Box>
  );
}
