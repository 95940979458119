export const Textarea = {
  variants: {
    adminInput: {
      background: 'rgba(249, 249, 249, 1)',
      fontSize: '14px',
      borderRadius: '5px',
      _placeholder: {
        color: 'neutral.60',
      },
    },
    outline: {
      _focus: {
        border: '1px',
        borderColor: 'brand.primary',
        boxShadow: 'none',
      },
    },
    landingPage: {
      _placeholder: {
        color: 'neutral.60',
      },
      backgroundColor: '#ffffff',
      border: '1px solid #E2E8F0',
      borderRadius: 'md',
      variant: 'unstyled',
      marginRight: 6,
      paddingLeft: 4,
      marginBottom: 4,
      paddingY: 2,
      fontSize: '14px',
    },
    generativeAi: {
      _disabled: {
        opacity: 1,
        color: 'brand.legibleBlack.80',
        backgroundColor: '#ffffff',
        fontWeight: 500,
      },
      border: '1px solid #E2E8F0',
      minHeight: 80,
      borderColor: 'brand.legibleBlack.40',
      padding: 6,
    },
    create: {
      fontSize: 'sm',
      minHeight: '200px',
      resize: 'none',
      overflowY: 'auto',
      borderRadius: 'md',
      border: '1px solid',
      borderColor: 'warmNeutral.200',
      _focusWithin: {
        borderColor: 'warmNeutral.500',
      },
      _placeholder: {
        color: 'neutral.400',
      },
      _invalid: {
        borderColor: 'red',
      },
    },
  },
};
