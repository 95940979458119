/* eslint-disable react/jsx-props-no-spreading */

import {
  useToken,
  Box,
  Checkbox,
  Flex,
  Input,
  Tooltip,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';

import type { ChoiceOptionData, QuestionData } from '@/client/services/api/graphql/gql/graphql';
import AssessmentCounter from '@/client/components/admin/create/content/input/AssessmentCounter';
import { CloseIcon } from '@chakra-ui/icons';
import { HamburgerDragHandle } from '@/client/components/icons/ContinuIcons';
import { useFormContext } from 'react-hook-form';
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import invariant from 'tiny-invariant';
// eslint-disable-next-line import/no-cycle
import { getChoiceOptionData, isChoiceOptionData } from './DraggableChoiceOptionList';

type SectionState =
  | {
      type: 'idle';
    }
  | {
      type: 'preview';
      container: HTMLElement;
    }
  | {
      type: 'is-dragging';
    }
  | {
      type: 'is-dragging-over';
      closestEdge: Edge | null;
    };

const idle: SectionState = { type: 'idle' };

interface DraggableChoiceOptionProps {
  question: QuestionData;
  choiceOption: ChoiceOptionData;
  choiceOptionIndex: number | undefined;
  index: number;
}

export default function DraggableChoiceOption({
  question,
  choiceOption,
  choiceOptionIndex,
  index,
}: DraggableChoiceOptionProps) {
  const { t } = useTranslation();
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [state, setState] = useState<SectionState>(idle);
  const [borderColor] = useToken('colors', ['warmNeutral.500']);
  const ref = useRef<HTMLDivElement>(null);

  const questions = watch('questions') as QuestionData[];

  const questionIndex = questions.findIndex((q) => q.id === question.id);

  const { format } = question;

  const handleCheckboxSelect = (co: ChoiceOptionData) => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === question.id) {
        return {
          ...q,
          choiceOptions: q?.choiceOptions?.map((option) => {
            if (option.id === co.id) {
              return {
                ...option,
                points: option.points === 0 ? 1 : 0,
                __typename: option.__typename, // Ensure __typename is preserved
              };
            }

            return option;
          }) as QuestionData['choiceOptions'], // Type assertion to ensure compatibility
        };
      }

      return q;
    });

    setValue('questions', updatedQuestions);
  };

  const handleRemoveChoiceOption = (co: ChoiceOptionData) => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === question.id) {
        return {
          ...q,
          choiceOptions: q?.choiceOptions?.filter((option) => option.id !== co.id),
        };
      }

      return q;
    });

    setValue('questions', updatedQuestions);
  };

  useEffect(() => {
    const element = ref.current;
    invariant(element);
    return combine(
      draggable({
        element,
        getInitialData() {
          return getChoiceOptionData(choiceOption);
        },
        onDragStart() {
          setState({ type: 'is-dragging' });
        },
        onDrop() {
          setState(idle);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          // * Don't allow items to drop on themselves
          if (source.element === element) {
            return false;
          }
          // * Only allow course section data to be dropped
          return isChoiceOptionData(source.data);
        },
        getData({ input }) {
          const data = getChoiceOptionData(choiceOption);
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ['top', 'bottom'],
          });
        },
        getIsSticky() {
          return true;
        },
        onDragEnter({ self }) {
          const closestEdge = extractClosestEdge(self.data);
          setState({ type: 'is-dragging-over', closestEdge });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          // * Only need to update react state if nothing has changed.
          // * Prevents re-rendering.
          setState((current) => {
            if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
              return current;
            }

            return { type: 'is-dragging-over', closestEdge };
          });
        },
        onDragLeave() {
          setState(idle);
        },
        onDrop() {
          setState(idle);
        },
      }),
    );
  }, [question]);

  return (
    <Flex
      ref={ref}
      key={choiceOption.id}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        opacity: state.type === 'is-dragging' ? 0.4 : 1,
        borderTop:
          state.type === 'is-dragging-over' && state.closestEdge === 'top'
            ? `1px solid ${borderColor}`
            : 'none',
        borderBottom:
          state.type === 'is-dragging-over' && state.closestEdge === 'bottom'
            ? `1px solid ${borderColor}`
            : 'none',
      }}
    >
      <HamburgerDragHandle
        color="neutral.400"
        marginRight={4}
        boxSize={4}
        _hover={{ cursor: 'grab' }}
      />

      {question.format !== 'short_input' && (
        <Tooltip
          label={`${t('assessment.textResponse.markCorrectAnswer')}`}
          aria-label={`${t('assessment.textResponse.markCorrectAnswer')}`}
          hasArrow
          placement="top"
        >
          <Flex alignItems="center">
            <Checkbox
              variant="assessment"
              size="lg"
              isChecked={choiceOption.points !== 0}
              onChange={() => handleCheckboxSelect(choiceOption)}
            />
          </Flex>
        </Tooltip>
      )}

      <FormControl
        paddingX={6}
        isInvalid={
          !!errors.questions &&
          choiceOptionIndex !== undefined &&
          !!(errors.questions as any)[questionIndex]?.choiceOptions?.[choiceOptionIndex]?.copy
        }
      >
        <Input
          {...(choiceOptionIndex !== undefined &&
            register(`questions.${questionIndex}.choiceOptions.${choiceOptionIndex}.copy`))}
          placeholder={`${
            format === 'short_input'
              ? t('assessment.textResponse.addAcceptableAnswer.placeholder')
              : t('assessment.textResponse.addAnswer.placeholder')
          }`}
          variant="ghost"
          borderRadius="none"
          _placeholder={{ fontSize: '14px', fontWeight: 400, color: 'neutral.600' }}
          _invalid={{ borderBottom: '1px solid', borderBottomColor: 'red' }}
        />

        {!!errors.questions &&
          choiceOptionIndex !== undefined &&
          !!(errors.questions as any)[questionIndex]?.choiceOptions?.[choiceOptionIndex]?.copy && (
            <FormErrorMessage>{t('assessment.error.provideAnswerText')}</FormErrorMessage>
          )}
      </FormControl>

      {choiceOption.points !== 0 && (
        <Box marginX={6}>
          <AssessmentCounter
            name={`questions.${questionIndex}.choiceOptions.${index}.points`}
            counterMin={1}
            counterMax={99}
            option="points"
            label="Points"
            buttonSize="xs"
            inputWidth={14}
            onHandleChange={(val) =>
              setValue(`questions.${questionIndex}.choiceOptions.${index}.points`, val)
            }
          />
        </Box>
      )}

      <Box>
        {question.choiceOptions && question.choiceOptions.length > 1 && (
          <CloseIcon
            marginTop={-5}
            color="neutral.400"
            _hover={{
              color: 'neutral.600',
              cursor: 'pointer',
            }}
            onClick={() => handleRemoveChoiceOption(choiceOption)}
          />
        )}
      </Box>
    </Flex>
  );
}
