import { Flex, Radio, RadioGroup } from '@chakra-ui/react';

import { ChoiceOption } from '@/client/types/content/assessments/Assessment';

interface MultipleChoiceQuestionProps {
  selected: string[];
  answers: ChoiceOption[];
  handleSelect: (id: string) => void;
}

export default function MultipleChoiceQuestion({
  selected,
  answers,
  handleSelect,
}: MultipleChoiceQuestionProps) {
  return (
    <RadioGroup value={selected.length ? selected[0] : ''}>
      <Flex wrap="wrap">
        {answers.map(({ id, _id, copy }) => (
          <Flex
            minWidth="50%"
            key={_id || id}
            onClick={(e) => {
              e.preventDefault();
              handleSelect(id || _id);
            }}
          >
            <Radio value={id || _id} padding={4}>
              {copy}
            </Radio>
          </Flex>
        ))}
      </Flex>
    </RadioGroup>
  );
}
