/* eslint-disable react/jsx-props-no-spreading */
import { background } from '@chakra-ui/react';
import Combobox from './Combobox';

interface TableComboboxProps {
  items: any;
  selectedItems: any[];
  setSelectedItems: any;
  searchTerm: string;
  setSearchTerm: any;
  isLoading?: boolean;
  tooltipText?: string;
}

export default function TableCombobox({
  items,
  selectedItems,
  setSelectedItems,
  searchTerm,
  setSearchTerm,
  isLoading,
  tooltipText = undefined
}: TableComboboxProps) {

  const tableCssOptions = {
    input: {
      borderRadius: '5px',
      color: 'neutral.1400',
      backgroundColor: 'neutral.50',
      border: 'none',
      _placeholder: { color: 'brand.grey.60' },
    },
    inputGroup: {
      size: 'sm',
      backgroundColor: 'brand.backgroundGrey',
      borderRadius: '5px',
    },
  };

  return (
    <Combobox
      tooltipText={tooltipText}
      items={items}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      isLoading={isLoading}
      positionAbsolute
      inputCssOptions={tableCssOptions.input}
      inputGroupCssOptions={tableCssOptions.inputGroup}
      width="40%"
      listWidth='36%'
      border="0.5px solid"
      borderColor="neutral.700"
      borderRadius='md'
      showQuestionIcon
      showSearchIcon
    />
  );
}

TableCombobox.defaultProps = {
  isLoading: false,
};
