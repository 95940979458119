import './VideoRecorder.scss';

import { Box, Button, Flex, Input, useToken } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useLazyLoadScript, useLazyLoadStylesheet } from '@/client/services/hooks/useLazyLoad';

import { Circle } from '@/client/components/icons/ContinuIcons';
import axios from 'axios';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import v3ApiService from '@/client/services/api/clients/v3ApiClient';

interface QuestionSettings {
  choose_attempt: boolean;
  manual_grade: boolean;
  manual_points: number;
  question_value_is_custom: boolean;
  recording_limit: number;
  recording_type: string;
}

interface VideoRecorderProps {
  questionSettings: QuestionSettings;
  allowUpload: boolean;
  setVideoUploading: (value: boolean) => void;
  setUploadProgress: (value: number) => void;
  onUploadDone: (videoUrl: string) => void;
  isPreview: boolean;
}

export default function VideoRecorder({
  questionSettings,
  allowUpload,
  setVideoUploading,
  setUploadProgress,
  onUploadDone,
  isPreview,
}: VideoRecorderProps) {
  const { setToast } = useToastStore();
  const [brandPrimaryColor] = useToken('colors', ['brand.primary']);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [recorder, setRecorder] = useState<any>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [showStartRecording, setShowStartRecording] = useState(false);
  const [canLoadScript, setCanLoadScript] = useState(false);
  const recordingType = questionSettings.recording_type;
  const { t } = useTranslation();

  const handleFileUploadClick = () =>
    fileUploadRef.current ? fileUploadRef.current.click() : null;

  const handleSelectedFileChange = (files: FileList | null) => {
    if (!files) return;

    if (files.length > 0) {
      const video = files[0];

      if (video.size > 1024000000) {
        setToast({
          show: true,
          status: 'error',
          title: t('edit.videos.1024_limit'),
        });

        return;
      }

      v3ApiService
        .getS3VideoPolicy(video.type, video.name)
        .then((policy) => {
          const formData = new FormData();

          formData.append('key', policy.Key);
          formData.append('Content-Type', policy.ContentType);
          formData.append('AWSAccessKeyId', policy.AWSAccessKeyId);
          formData.append('success_action_status', '201');
          formData.append('policy', policy.S3Policy);
          formData.append('signature', policy.S3Signature);
          formData.append('file', video);

          setVideoUploading(true);

          axios
            .post('https://' + policy.Bucket + '.s3.amazonaws.com/', formData, {
              onUploadProgress: (progressEvent) => {
                setUploadProgress((progressEvent.loaded / progressEvent.total) * 100);
              },
            })
            .then(() => {
              if (!policy.CloudFrontLink) return;

              console.log('policy.CloudFrontLink', policy.CloudFrontLink);

              onUploadDone(policy.CloudFrontLink);
              setVideoUploading(false);
            })
            .catch((error) => {
              console.log('error', error);
              setToast({
                show: true,
                status: 'error',
                title: t('edit.videos.contents_error'),
              });
            });
        })
        .catch((error) => {
          console.log('error', error);
          setToast({ show: true, status: 'error', title: error.message });
        });
    }
  };

  useEffect(() => {
    if (questionSettings) {
      setCanLoadScript(true);
    }
  }, [questionSettings]);

  const initializePipe = () => {
    const languageFile = 'https://' + window.location.host + '/public/en.xml';
    const recordingLimit = 60 * (questionSettings?.recording_limit || 1);

    const pipeParams = {
      lang: languageFile,
      size: { width: 640, height: 390 },
      qualityurl: 'avq/720p.xml',
      accountHash: '8d4c7c11c5145404e2e33679193ee1b1',
      eid: 1,
      showMenu: 1,
      mrt: recordingLimit,
      sis: 0,
      asv: 1,
      mv: 0,
      dpv: 0,
      ao: 0,
      dup: 0,
      bgCol: brandPrimaryColor,
    };

    if (questionSettings?.recording_type === 'screen_voice') {
      // @ts-ignore
      pipeParams.srec = 1;
    }

    // @ts-ignore
    PipeSDK.insert('continu-video-recorder', pipeParams, (recorder: any) => {
      if (recordingType === 'screen_voice') {
        const startRecordingButton = document.getElementById(
          'pipeStartRecording-continu-video-recorder',
        );
        if (startRecordingButton?.parentNode) {
          startRecordingButton.parentNode.removeChild(startRecordingButton);
        }
      }

      // TODO: Work this out
      // self.keep_active_interval_id = setInterval(function() {
      //   Application.authorization.setActive();
      // }, 10 * 60 * 1000);

      setRecorder(recorder);

      recorder.onRecordingStarted = () => setIsRecording(true);

      recorder.onReadyToRecord = () => {
        if (questionSettings.recording_type === 'screen_voice') {
          recorder.record();
        }

        setShowStartRecording(true);
      };

      recorder.btRecordPressed = () => setIsRecording(true);

      recorder.btStopRecordingPressed = () => {
        setIsRecording(false);
        setShowStartRecording(false);
      };

      recorder.onUploadDone = (
        recorderId: any,
        streamName: any,
        streamDuration: any,
        audioCodec: any,
        videoCodec: any,
        fileType: any,
        audioOnly: any,
        location: any,
      ) => {
        const link =
          'https://' + location + '/8d4c7c11c5145404e2e33679193ee1b1/' + streamName + '.mp4';

        onUploadDone(link);
      };
    });
  };

  useLazyLoadScript('https://cdn.addpipe.com/2.0/pipe.js', canLoadScript, initializePipe);
  useLazyLoadStylesheet('https://cdn.addpipe.com/2.0/pipe.css');

  return (
    <Box className="record__overlay">
      <Box
        id="continu-video-recorder"
        marginBottom={4}
        pointerEvents={isPreview ? 'none' : 'auto'}
      />

      {!isRecording && showStartRecording && (
        <Button
          className="recordOverlay__overlay"
          isDisabled={isPreview}
          onClick={() => recorder.record()}
        >
          {t('view.assessment.startRecording')}
        </Button>
      )}

      {isRecording && (
        <Box className="recordOverlay__indicator">
          <Circle color="red" marginRight={2} /> REC
        </Box>
      )}

      {allowUpload && (
        <Flex position="absolute" width="full" bottom={10} justifyContent="center">
          <Input
            display="none"
            ref={fileUploadRef}
            type="file"
            accept="video/mp4,video/webm"
            onChange={(e) => handleSelectedFileChange(e.target.files)}
          />

          <Button size="xs" isDisabled={isPreview} onClick={() => handleFileUploadClick()}>
            {t('vidQuestionPresenter.orUploadResponse')}
          </Button>
        </Flex>
      )}
    </Box>
  );
}
