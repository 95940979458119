import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { Modal, ModalContent } from '@chakra-ui/react';

interface AssessmentPreviewModalProps {
  children: React.ReactNode;
}

export default function AssessmentPreviewModal({ children }: AssessmentPreviewModalProps) {
  const { assessmentPreviewModalIsOpen, setAssessmentPreviewModalIsOpen } = useCreateStore();

  const onClose = () => setAssessmentPreviewModalIsOpen(false);

  return (
    <Modal size="full" isOpen={assessmentPreviewModalIsOpen} onClose={onClose}>
      <ModalContent marginTop={50}>{children}</ModalContent>
    </Modal>
  );
}
