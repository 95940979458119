/* eslint-disable react/jsx-props-no-spreading */
import { Box, Flex, HStack, Text, useRadio } from '@chakra-ui/react';

export default function CreateRadio(props: any) {
  const { label, icon, isDisabled } = props;
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const { checked } = input;
  const checkbox = getRadioProps();

  return (
    <Box as="label" width="50%">
      <input {...input} />

      <Flex
        {...checkbox}
        justifyContent="center"
        border="1px solid"
        borderRadius="6px"
        backgroundColor="white"
        borderColor="warmNeutral.200"
        padding={4}
        _hover={{
          borderColor: 'warmNeutral.700',
        }}
        opacity={isDisabled ? 0.5 : 1}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        _checked={{
          backgroundColor: 'warmNeutral.0',
          borderColor: 'warmNeutral.700',
        }}
      >
        <HStack>
          {icon}

          <Text fontSize="14px" fontWeight={500} color={checked ? 'baseBlack' : 'neutral.900'}>
            {label}
          </Text>
        </HStack>
      </Flex>
    </Box>
  );
}
