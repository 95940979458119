import AssessmentDetailsForm from './AssessmentDetailsForm';
import AssessmentPreviewModal from '../../overlay/AssessmentPreviewModal';
import AssessmentPublishForm from './AssessmentPublishForm';
import AssessmentQuestionForm from './AssessmentQuestionForm';
import AssessmentSettingsForm from './AssessmentSettingsForm';
import AssessmentView from '@/client/routes/content/assessments/AssessmentView';
import ContentCreateTabControls from '../../tabs/ContentCreateTabControls';
import { ContentTypes } from '@/client/services/api/graphql/gql/graphql';
import CreateTabs from '@/client/components/admin/create/shared/tabs/CreateTabs';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import Loading from '@/client/components/media/Loading';
import { useAuthStore } from '@/client/services/state/authStore';
import { useAuthorAssessment } from '@/client/services/hooks/admin/authoring/useAuthorAssessment';
import { useCheckContentEditable } from '@/client/services/hooks/admin/authoring/useCheckContentEditable';
import { useCreateNavigationBlocker } from '@/client/services/hooks/admin/authoring/useCreateNavigationBlocker';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useValidateMediaAssessmentQuestionBuilder } from '@/client/services/hooks/admin/authoring/assessments/useValidateMediaAssessmentQuestionBuilder';
import { useValidateTextAssessmentQuestionBuilder } from '@/client/services/hooks/admin/authoring/assessments/useValidateTextAssessmentQuestionBuilder';

export default function EditAssessmentForm() {
  useCreateNavigationBlocker();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { tabs, setTabs, tabIndex, setTabIndex, setContentType } = useCreateStore();
  const { getAssessmentForEditor, submitAssessmentMutation, submitAssessmentPayload } =
    useAuthorAssessment(id);
  const { checkContentEditable } = useCheckContentEditable();
  const { validateTextAssessmentQuestionBuilder } = useValidateTextAssessmentQuestionBuilder();
  const { validateMediaAssessmentQuestionBuilder } = useValidateMediaAssessmentQuestionBuilder();
  const {
    formState: { isValid },
    watch,
  } = useFormContext();

  const { assessmentType, contentType, description, author, title, questions } = watch();

  const { data, isFetching, isError, error } = getAssessmentForEditor;

  useEffect(() => checkContentEditable(data), [data]);

  useEffect(() => {
    setContentType('assessment');
    setTabIndex(0);
  }, []);

  const tabOptions = [
    {
      label: t('assessment.tabs.details'),
      content: <AssessmentDetailsForm />,
      isDisabled: false,
    },
    {
      label: t('assessment.tabs.questions'),
      content: <AssessmentQuestionForm />,
      isDisabled: true,
    },
    {
      label: t('assessment.tabs.settings'),
      content: <AssessmentSettingsForm />,
      isDisabled: true,
    },
    {
      label: t('assessment.tabs.publish'),
      content: <AssessmentPublishForm />,
      isDisabled: true,
    },
  ];

  const renderAssessmentPreviewButtonTerms =
    contentType === ContentTypes.Assessment &&
    assessmentType &&
    description !== '' &&
    author !== '' &&
    title !== '' &&
    questions[0]?.question;

  useEffect(() => {
    setTabs(tabOptions);
    setContentType('assessment');
    setTabIndex(0);
  }, []);

  const handleOnProceed = () => {
    if (tabIndex === 1) {
      if (assessmentType === 'text') {
        validateTextAssessmentQuestionBuilder();

        return;
      }

      if (assessmentType === 'video' || assessmentType === 'file') {
        validateMediaAssessmentQuestionBuilder();

        return;
      }
    }

    setTabIndex(tabIndex + 1);
  };

  const handlePublish = () => {
    if (assessmentType === 'text') {
      validateTextAssessmentQuestionBuilder(
        false,
        submitAssessmentMutation.mutateAsync({
          approval_required: user.is_collaborator,
          approved: !user.is_collaborator,
        }),
      );

      return;
    }

    validateMediaAssessmentQuestionBuilder(
      false,
      submitAssessmentMutation.mutateAsync({
        approval_required: user.is_collaborator,
        approved: !user.is_collaborator,
      }),
    );
  };

  if (id && isFetching) return <Loading />;

  if (isError) {
    console.error('Error Fetching Assessment', error);

    return (
      <ErrorAlert
        title="There was an error fetching the assessment"
        backAction={{
          to: '/pvt/#/overview/assessments',
          label: 'Back to assessments overview',
          isAngular: true,
        }}
      />
    );
  }

  return (
    <>
      <CreateTabs
        isAssessment
        tabOptions={tabs}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        controls={
          <ContentCreateTabControls
            canPublish={isValid}
            tabOptionsLength={tabs.length}
            onProceed={handleOnProceed}
            handlePublish={handlePublish}
            renderAssessmentPreviewButton={renderAssessmentPreviewButtonTerms}
          />
        }
      />
      <AssessmentPreviewModal>
        <AssessmentView previewData={submitAssessmentPayload} />
      </AssessmentPreviewModal>
    </>
  );
}
