import { Stack, Text } from '@chakra-ui/react';

import { DefaultTFuncReturn } from 'i18next';

interface ContentFormTextGroupProps {
  label: string | DefaultTFuncReturn;
  helpText?: string | DefaultTFuncReturn;
  showBorder?: boolean;
}

export default function ContentFormTextGroup({
  label,
  helpText = undefined,
  showBorder = true,
}: ContentFormTextGroupProps) {
  return (
    <Stack
      spacing={2}
      minWidth="30%"
      borderLeft={showBorder ? '1px solid' : 'none'}
      borderLeftColor="warmNeutral.500"
      paddingLeft={showBorder ? 4 : 0}
    >
      <Text variant="createLabel">{label}</Text>

      {helpText && <Text variant="createHelpText">{helpText}</Text>}
    </Stack>
  );
}
