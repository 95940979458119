import { Close } from '@/client/components/icons/ContinuIcons';
import AssessmentService from '@/client/services/api/AssessmentService';
import { useTextAssessmentStore } from '@/client/services/state/content/textAssessmentStore';
import { Assessment, Question } from '@/client/types/content/assessments/Assessment';
import { Box, Button, Container, Flex, Stack, Text } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TextAssessmentQuestion from './TextAssessmentQuestion';

interface QuestionPresenterProps {
  refetchUserAssessment: () => void;
  setRetakingAssessment: (retakingAssessment: boolean) => void;
  handleCompleteContent: Function;
  previewData?: Assessment;
}

export default function QuestionPresenter({
  refetchUserAssessment,
  setRetakingAssessment,
  handleCompleteContent,
  previewData = undefined
}: QuestionPresenterProps) {
  const navigate = useNavigate();
  const [formattedPages, setFormattedPages] = useState<Question[][]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation();

  const {
    assessment,
    userAssessment,
    inProgressResponses,
    setInProgressResponses,
    answeredQuestions,
    progress,
    setViewingAssessment,
    setCanSubmit,
    canSubmit,
    showIntro,
    inTrack,
    inJourney,
  } = useTextAssessmentStore();

  const inProgressRef = useRef(inProgressResponses);
  inProgressRef.current = inProgressResponses;

  const { questions: assessmentQuestions } = assessment;
  interface SendResponseVariables {
    payload: { [key: string]: string[] };
  }

  const questions = previewData?.questions || assessmentQuestions;
  const pageBreaks = previewData
    ? previewData.questions.filter((q) => q.format === 'page_break').length
    : 0;

  const sendResponse = useMutation({
    mutationFn: ({ payload }: SendResponseVariables) =>
      AssessmentService.sendInProgressTextAssessmentResponses(userAssessment._id, payload),
    onSuccess: (res) => {
      const inProgress = Object.entries(res.in_progress_responses).map(([key, value]) => ({
        questionId: key,
        answers: value,
      }));

      setInProgressResponses(inProgress);
    },
  });

  const submit = useMutation({
    mutationFn: () => handleCompleteContent(),
    onSuccess: () => {
      setInProgressResponses([]);
      setRetakingAssessment(false);
      refetchUserAssessment();
    },
  });

  const sendInProgressResponses = async () => {
    const payloadObject = Object.fromEntries(
      inProgressRef.current.map((response) => [response.questionId, response.answers]),
    );

    return sendResponse.mutateAsync({ payload: payloadObject });
  };

  useEffect(
    () => () => {
      sendInProgressResponses();
    },
    [],
  );

  const handleAnswer = (questionId: string, answers: string[]) => {
    const current = inProgressResponses.find((response) => response.questionId === questionId);

    let updated: { questionId: string; answers: string[] }[] = [];

    if (current) {
      updated = inProgressResponses.map((response) => {
        if (response.questionId === questionId) {
          return { questionId, answers };
        }

        return response;
      });
    } else {
      updated = [...inProgressResponses, { questionId, answers }];
    }

    setInProgressResponses(updated);
  };

  useEffect(() => {
    const pages: Question[][] = [];

    for (let i = 0; i < questions.length; i += 1) {
      const currentPageQuestions = questions.filter((question) => question.page === i);

      pages[i] = currentPageQuestions;
    }

    setFormattedPages(pages.filter((page) => page.length > 0));
  }, [questions]);

  const handleSubmitAssessment = () => {
    setCanSubmit(false);
    sendInProgressResponses().then(() => {
      submit.mutate();
    });
  };

  const debouncedSubmit = useCallback(
    debounce(() => handleSubmitAssessment(), 300, { leading: true, trailing: false }),
    [],
  );

  return (
    <Box minHeight="100vh" backgroundColor="#f7f7f7" paddingTop={12}>
      {!inTrack && !inJourney && !previewData && (
        <Close
          type="close"
          position="absolute"
          top="60px"
          right={5}
          fontSize="2xl"
          _hover={{ cursor: 'pointer' }}
          onClick={() => navigate('/explore')}
        />
      )}

      <Container maxWidth="container.md" backgroundColor="white" padding={12} marginBottom="50px">
        <Stack>
          {formattedPages.length &&
            formattedPages[currentPage].map((question) => {
              const response = inProgressResponses.find(
                (userResponse) => userResponse.questionId === question.id,
              );

              let currentAnswer: string[] = [];

              if (response && response.answers.length > 0) {
                currentAnswer = response.answers;

                return (
                  <TextAssessmentQuestion
                    key={question.id}
                    question={question}
                    inProgressResponse={currentAnswer}
                    handleAnswer={handleAnswer}
                  />
                );
              }

              return (
                <TextAssessmentQuestion
                  key={question.id}
                  question={question}
                  handleAnswer={handleAnswer}
                />
              );
            })}
        </Stack>
      </Container>

      <Box position="fixed" bottom="0" left="50px" right="0px" zIndex={10}>
        <Box
          height="3px"
          bgGradient="linear(to-r, brand.primary, brand.secondary)"
          width={`${progress}%`}
        />

        <Flex
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="white"
          paddingX={4}
          paddingY={2}
        >
          <Box>
            <Button
              display={currentPage === 0 && !showIntro ? 'none' : 'block'}
              onClick={() => {
                sendInProgressResponses();
                currentPage > 0 ? setCurrentPage(currentPage - 1) : setViewingAssessment(false);
              }}
            >
              {t('global.form.labels_previous')}
            </Button>
          </Box>

          <Text>
            {answeredQuestions}/{questions.length - pageBreaks}{' '}
            {questions.length > 1
              ? t('assessViewer.questions_answered')
              : t('assessViewer.question_answered')}
          </Text>

          <Button
            isDisabled={currentPage + 1 === formattedPages.length && !canSubmit}
            onClick={() => {
              if (currentPage + 1 === formattedPages.length) {
                if (previewData) {
                  return;
                }
                debouncedSubmit();
                return;
              }

              sendInProgressResponses();
              setCurrentPage(currentPage + 1);
            }}
          >
            {currentPage + 1 === formattedPages.length
              ? t('global.forms.labels_submit')
              : t('global.form.labels_next')}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
