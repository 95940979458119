/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Flex, HStack, Input, Stack, Text } from '@chakra-ui/react';
import { OpenGrading, Plus, RubricGrading } from '@/client/components/icons/ContinuIcons';

import AssessmentCounter from '@/client/components/admin/create/content/input/AssessmentCounter';
import { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';
import AssessmentRadioGroup from '@/client/components/admin/create/content/input/AssessmentRadioGroup';
import { CloseIcon } from '@chakra-ui/icons';
import ContentFormTextGroup from '@/client/components/admin/create/content/text/ContentFormTextGroup';
import TinyMceEditor from '@/client/components/admin/create/shared/TinyMceEditor';
import { makeId } from '@/client/utils/admin/create/makeId';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useValidateMediaAssessmentQuestionBuilder } from '@/client/services/hooks/admin/authoring/assessments/useValidateMediaAssessmentQuestionBuilder';

export default function MediaResponseGradingForm() {
  const { t } = useTranslation();
  const { watch, setValue, register } = useFormContext<AssessmentFormData>();
  const { validateMediaAssessmentQuestionBuilder } = useValidateMediaAssessmentQuestionBuilder();

  const question = watch('questions')[0];

  useEffect(() => {
    validateMediaAssessmentQuestionBuilder(true);
  }, [question, question?.question, question?.rubricOptions]);

  const { rubricOptions, rubricGrading: useRubricGrading } = question;

  const totalPoints = rubricOptions?.reduce((acc, option) => acc + option.points!, 0);

  const manualPointsIndex = question?.questionOptions?.findIndex(
    (data) => data.name === 'manual_points',
  );

  const graderInstructionsIndex = question?.questionOptions?.findIndex(
    (data) => data.name === 'grader_instructions',
  );

  const newRubricOption = {
    id: makeId(),
    name: '',
    description: '',
    points: 1,
  };

  const defaultRubricOptions = [
    {
      id: makeId(),
      name: t('edit.assessment.name_1'),
      description: t('edit.assessment.description_1'),
      points: 1,
    },
    {
      id: makeId(),
      name: t('edit.assessment.name_2'),
      description: t('edit.assessment.description_2'),
      points: 1,
    },
    {
      id: makeId(),
      name: t('edit.assessment.name_3'),
      description: t('edit.assessment.description_3'),
      points: 1,
    },
  ];

  useEffect(() => {
    if (!useRubricGrading) return;

    if (question.rubricOptions && question.rubricOptions.length > 0) return;

    const updatedQuestion = {
      ...question,
      rubricOptions: defaultRubricOptions,
    };

    // @ts-ignore
    setValue('questions', [updatedQuestion]);
  }, []);

  const handleGradingTypeChange = (value: string) => {
    const updatedQuestion = {
      ...question,
      rubricGrading: value === 'true',
    };

    // @ts-ignore
    setValue('questions', [updatedQuestion]);
  };

  return (
    <Box>
      <Box marginBottom={6}>
        <ContentFormTextGroup
          label={t('authoring.assessment.grading.label')}
          helpText={t('authoring.assessment.grading.helpText')}
        />
      </Box>

      <AssessmentRadioGroup
        name="questions.0.rubricGrading"
        defaultValue={useRubricGrading ? 'true' : 'false'}
        label=""
        onHandleChange={handleGradingTypeChange}
        options={[
          {
            value: 'true',
            label: t('authoring.assessment.grading.defineCriteria'),
            icon: <RubricGrading boxSize={10} />,
          },
          {
            value: 'false',
            label: t('authoring.assessment.grading.openGrading'),
            icon: <OpenGrading boxSize={10} />,
          },
        ]}
      />

      {useRubricGrading && (
        <>
          <Stack spacing={8} marginTop={10} marginBottom={8}>
            {question.rubricOptions &&
              question.rubricOptions.map((option, index) => (
                <Flex key={option.id} alignItems="center">
                  <Text
                    alignSelf="baseline"
                    fontSize="16px"
                    fontWeight={600}
                    color="baseBlack"
                    marginTop={1}
                  >{`${index + 1}.`}</Text>

                  <Stack flex={1} spacing={6} marginX={4}>
                    <Input
                      {...register(`questions.${0}.rubricOptions.${index}.name`, {
                        required: true,
                      })}
                      placeholder={`${t('authoring.assessment.grading.newCriteria')}`}
                      variant="ghost"
                      fontSize="16px"
                      fontWeight={600}
                      height="fit-content"
                      _placeholder={{ color: 'neutral.500' }}
                      borderBottom="1px solid"
                      borderBottomColor="neutral.50"
                      borderRadius={0}
                    />

                    <Input
                      {...register(`questions.${0}.rubricOptions.${index}.description`)}
                      placeholder={`${t('authoring.assessment.grading.newCriteria.description')}`}
                      variant="ghost"
                      fontSize="14px"
                      fontWeight={400}
                      height="fit-content"
                      _placeholder={{ color: 'neutral.500' }}
                      borderBottom="1px solid"
                      borderBottomColor="neutral.50"
                      borderRadius={0}
                    />
                  </Stack>

                  <HStack spacing={4}>
                    <AssessmentCounter
                      name={`questions.${0}.rubricOptions.${index}.points`}
                      counterMin={1}
                      counterMax={99}
                      option="points"
                      label={t('authoring.assessment.grading.points')}
                      buttonSize="xs"
                      inputWidth={14}
                      onHandleChange={() => {}}
                    />

                    <CloseIcon
                      marginTop={-5}
                      color="neutral.400"
                      _hover={{
                        color: 'neutral.600',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setValue(
                          'questions.0.rubricOptions',
                          rubricOptions!.filter((_, i) => i !== index),
                        );
                      }}
                    />
                  </HStack>
                </Flex>
              ))}
          </Stack>

          <Flex width="full" justifyContent="space-between" alignItems="center">
            <Button
              variant="createNeutral"
              leftIcon={<Plus />}
              onClick={() => {
                setValue('questions.0.rubricOptions', [...(rubricOptions || []), newRubricOption]);
              }}
            >
              {t('authoring.assessment.grading.addCriteria')}
            </Button>

            <Text fontSize="16px" fontWeight={600} color="neutral.600">
              {t('authoring.assessment.grading.maxPoints')}{' '}
              <Text as="span" color="baseBlack">
                {totalPoints}
              </Text>
            </Text>
          </Flex>
        </>
      )}

      {!useRubricGrading && (
        <Stack>
          <Flex marginTop={10} marginLeft={2} width="full" justifyContent="space-between">
            <ContentFormTextGroup
              showBorder={false}
              label={t('authoring.assessment.grading.pointValue.label')}
              helpText={t('authoring.assessment.grading.pointValue.helpText')}
            />

            <Flex justifyContent="flex-end">
              <AssessmentCounter
                name={`questions.0.questionOptions.${manualPointsIndex}.value`}
                counterMin={1}
                counterMax={99}
                option="points"
                label={t('authoring.assessment.grading.points')}
                onHandleChange={() => {}}
              />
            </Flex>
          </Flex>

          <Stack spacing={6} marginY={8}>
            <ContentFormTextGroup
              label={t('authoring.assessment.grading.graderInstructions.label')}
              helpText={t('authoring.assessment.grading.graderInstructions.helpText')}
            />

            <TinyMceEditor
              isRequired={false}
              formName={`questions.0.questionOptions.${graderInstructionsIndex}.value`}
              placeholder={t('authoring.assessment.grading.graderInstructions.placeholder')}
              label=""
              toolbarOpts={false}
              useMenu={false}
              minHeight={50}
            />
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
