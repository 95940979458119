/* eslint-disable react/jsx-props-no-spreading */

import {
  ContentTypes,
  PartnerPermissionTypes,
  type AssessmentExtras,
  type Content,
} from '@/client/services/api/graphql/gql/graphql';

import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import EditAssessmentForm from '@/client/components/admin/create/content/forms/assessments/EditAssessmentForm';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useEffect } from 'react';
import { makeId } from '@/client/utils/admin/create/makeId';
import TrackStateAlert from '@/client/components/admin/create/content/overlay/TrackStateAlert';

type AssessmentFormValues = {
  enable_additional_graders: boolean;
};

export type AssessmentFormData = Omit<Content, 'creator' | 'ratingConfiguration'> &
  Omit<Omit<AssessmentExtras, 'parent'>, 'categoryLineage'> &
  AssessmentFormValues;

const defaultFileQuestion = {
  id: makeId(),
  page: 0,
  position: 0,
  format: 'file',
  question: '',
  choiceOptions: [],
  rubricGrading: true,
  questionOptions: [
    {
      name: 'manual_grade',
      value: true,
    },
    {
      name: 'question_value_is_custom',
      value: true,
    },
    {
      name: 'manual_points',
      value: 1,
    },
    {
      name: 'upload_type',
      value: '',
    },
    {
      name: 'grader_instructions',
      value: '',
    },
  ],
};

const defaultVideoQuestion = {
  id: makeId(),
  page: 0,
  position: 0,
  format: 'video',
  question: '',
  choiceOptions: [],
  rubricGrading: true,
  questionOptions: [
    {
      name: 'manual_grade',
      value: true,
    },
    {
      name: 'question_value_is_custom',
      value: true,
    },
    {
      name: 'choose_attempt',
      value: false,
    },
    {
      name: 'manual_points',
      value: 1,
    },
    {
      name: 'recording_limit',
      value: 5,
    },
    {
      name: 'recording_type',
      value: '',
    },
    {
      name: 'allow_upload',
      value: false,
    },
    {
      name: 'max_attempts',
      value: 1,
    },
  ],
};

const assessmentDefaultValues: AssessmentFormData = {
  id: '',
  allowRetake: false,
  assessmentType: '',
  assignedGraders: null,
  author: '',
  description: '',
  hideCover: false,
  numberOfRetakes: 0,
  percentToPass: 100,
  questions: [],
  showUserCorrect: false,
  showUserCorrectAfterFail: false,
  showUserGrade: false,
  title: '',
  contentType: ContentTypes.Assessment,
  partnerPermissions: PartnerPermissionTypes.Tenant,
  exploreHide: false,
  archived: false,
  company: '',
  enable_additional_graders: false,
};

export default function EditAssessment() {
  useDocumentTitle('Edit Assessment');
  const { id } = useParams<{ id: string }>();
  const { isAllowed } = useAccessHelper();

  const allowance = {
    create_assessment: isAllowed(
      ['admin', 'content', 'all_create'],
      [['admin', 'content', 'assessments_create']],
      ['admin', 'creator', 'collaborator'],
    ),
    edit_assessment: isAllowed(
      ['admin', 'content', 'all_edit'],
      [
        ['admin', 'content', 'assessments_edit'],
        ['admin', 'content', 'my_content_edit'],
      ],
      ['admin', 'creator', 'collaborator'],
    ),
  };

  if ((id && !allowance.edit_assessment) || (!id && !allowance.create_assessment)) {
    return <Navigate to="/explore" replace />;
  }

  const methods = useForm<AssessmentFormData>({
    defaultValues: assessmentDefaultValues,
    mode: 'all',
  });

  const { watch, setValue } = methods;

  const { assessmentType } = watch();

  useEffect(() => {
    if (!id && assessmentType === 'text') setValue('questions', []);

    if (!id && assessmentType === 'video') setValue('questions', [defaultVideoQuestion]);

    if (!id && assessmentType === 'file') setValue('questions', [defaultFileQuestion]);
  }, [assessmentType]);

  return (
    <>
      <Box minHeight="100vh" maxWidth="915px" margin="0 auto">
        <FormProvider {...methods}>
          <EditAssessmentForm />
        </FormProvider>
      </Box>

      <TrackStateAlert contentType="assessment" />
    </>
  );
}
