import type { AssessmentSubmissionData } from '@/client/types/admin/content-authoring/assessments/AssessmentSubmissionData';
import { learnApiClient } from '../../clients/learnApiClient';

type SubmissionData = Omit<
  AssessmentSubmissionData,
  | '_id'
  | 'type'
  | 'author'
  | 'surveys'
  | 'rating_configuration'
  | 'allow_comments'
  | 'private'
  | 'duration'
>;

const submitAssessment = async (data: SubmissionData, assessmentId?: string) => {
  let url = '/assessments';

  if (assessmentId) {
    url = `/assessments/${assessmentId}`;
  }

  const response = await learnApiClient.post(url, data);

  return response.data;
};

const AuthoringAssessmentsService = {
  submitAssessment,
};

export default AuthoringAssessmentsService;
