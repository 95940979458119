import {
  ArrowRight,
  QuestionCircle,
  Redo,
  WarningTriangle,
} from '@/client/components/icons/ContinuIcons';
import {
  Assessment,
  InProgressResponses,
  UserAssessment,
} from '@/client/types/content/assessments/Assessment';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Progress,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import AssessmentService from '@/client/services/api/AssessmentService';
import Carousel from '@/client/components/lists/carousel/Carousel';
import HtmlRenderer from '@/client/components/html/HtmlRenderer';
import Loading from '@/client/components/media/Loading';
import VideoPlayer from '@/client/components/media/video/VideoPlayer';
import VideoRecorder from '@/client/components/media/video/VideoRecorder';
import { getQuestionOptions } from '@/client/utils/content/assessments/getQuestionOptions';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';

interface VideoAssessmentProps {
  assessment: Assessment;
  userAssessment: UserAssessment;
  setRetakingAssessment: (value: boolean) => void;
  refetch: () => void;
  handleCompleteContent: Function;
  isPreview?: boolean;
}

interface QuestionSettings {
  choose_attempt: boolean;
  manual_grade: boolean;
  manual_points: number;
  question_value_is_custom: boolean;
  recording_limit: number;
  recording_type: string;
}

export default function VideoAssessment({
  assessment,
  userAssessment,
  setRetakingAssessment,
  refetch,
  handleCompleteContent,
  isPreview = false,
}: VideoAssessmentProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const [questionSettings, setQuestionSettings] = useState<QuestionSettings | null>(null);
  const [totalUserAttempts, setTotalUserAttempts] = useState(0);
  const [inProgressAnswers, setInProgessAnswers] = useState<string[]>([]);
  const [selectedAttempt, setSelectedAttempt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [labelColor, setLabelColor] = useState('gray.400');
  const [videoUploading, setVideoUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const maxAttempts = assessment.questions[0].question_options.find(
    (item) => item.name === 'max_attempts',
  )?.value;
  const chooseAttempt = assessment.questions[0].question_options.find(
    (item) => item.name === 'choose_attempt',
  )?.value;
  const allowUpload = assessment.questions[0].question_options.find(
    (item) => item.name === 'allow_upload',
  )?.value as boolean;
  const [timeLimit, setTimeLimit] = useState<{
    minutes?: number;
    seconds?: number;
  }>();

  const submitAssessment = useMutation({
    mutationFn: () => handleCompleteContent(),
    onSuccess: () => {
      setRetakingAssessment(false);
      refetch();
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: t('videoAssessments.submittedAssessmentError'),
      });
    },
  });

  const updateInProgressAnswers = useMutation({
    mutationFn: (answers: string[]) =>
      AssessmentService.sendInProgressResponses(
        userAssessment._id,
        assessment.questions[0].id,
        answers,
      ),

    onSuccess: () => {
      setIsLoading(true);

      setTimeout(() => {
        refetch();
        setIsLoading(false);
      }, 4000);
    },
    onError: (error) => console.log('error', error),
  });

  const getTimeVals = (limit: number | undefined) => {
    if (limit) {
      const minutes = Math.floor(limit);
      const seconds = Math.floor((limit - minutes) * 60);
      setTimeLimit({
        minutes,
        seconds,
      });
    }
  };

  useEffect(() => {
    if (userAssessment && userAssessment.in_progress_responses) {
      const userInProgressAnswers =
        userAssessment.in_progress_responses[
          assessment.questions[0].id as keyof InProgressResponses
        ];

      setInProgessAnswers(userInProgressAnswers);
      setTotalUserAttempts(userInProgressAnswers.length);

      if (userInProgressAnswers.length > 0) {
        setSelectedAttempt(userInProgressAnswers[0]);
      }

      userInProgressAnswers.length > 0 && setLabelColor('orange.400');

      userInProgressAnswers.length + 1 === maxAttempts && setLabelColor('red.400');
    }

    const questionOptions = getQuestionOptions(assessment.questions[0]);

    setQuestionSettings(questionOptions);

    getTimeVals(questionOptions.recording_limit);
  }, [userAssessment, assessment]);

  const onSubmitAssessment = () => {
    const filteredArray = inProgressAnswers.filter((item) => item !== selectedAttempt);
    const arrWithSelection = [selectedAttempt, ...filteredArray];

    updateInProgressAnswers.mutate(arrWithSelection);

    if (updateInProgressAnswers.status === 'success') {
      submitAssessment.mutate();
      setSelectedAttempt('');
    }
  };

  const handleUploadComplete = (link: string) =>
    updateInProgressAnswers.mutate([link, ...inProgressAnswers]);

  useDocumentTitle(isPreview ? 'Edit Assessment' : 'View Assessment');

  return (
    <Box height="100vh">
      <Center>
        {submitAssessment.isLoading && <Loading />}

        {videoUploading && (
          <Box
            marginTop={12}
            backgroundColor="white"
            width={{ base: 'full', lg: 'container.md' }}
            padding={12}
            boxShadow="sm"
          >
            <Text as="h3">Your video upload is in progress...</Text>

            <Progress size="lg" marginBottom={4} value={uploadProgress} />

            <Text fontSize="xs">{t('videoQuestionPresenter.onceYourVideoUploads')}</Text>
          </Box>
        )}

        {!submitAssessment.isLoading && !videoUploading && (
          <Box
            marginTop={12}
            backgroundColor="white"
            width={{ base: 'full', lg: 'container.md' }}
            padding={12}
            boxShadow="sm"
          >
            <Text as="h4">{assessment.questions[0].question}</Text>

            {selectedAttempt !== '' && (
              <Box>
                <VideoPlayer url={selectedAttempt} setRenderRecommendations={() => {}} />
              </Box>
            )}

            {selectedAttempt === '' && questionSettings && (
              <VideoRecorder
                allowUpload={allowUpload}
                questionSettings={questionSettings as QuestionSettings}
                setVideoUploading={setVideoUploading}
                setUploadProgress={setUploadProgress}
                onUploadDone={handleUploadComplete}
                isPreview={isPreview}
              />
            )}

            <Flex
              justifyContent={selectedAttempt === '' ? 'flex-end' : 'space-between'}
              alignItems="center"
              marginBottom={6}
            >
              {maxAttempts && totalUserAttempts < +maxAttempts && selectedAttempt !== '' && (
                <Button
                  variant="ghost"
                  size="xs"
                  leftIcon={<Redo />}
                  isDisabled={isPreview}
                  onClick={() => setSelectedAttempt('')}
                >
                  Record a new attempt
                </Button>
              )}

              {questionSettings?.recording_limit && timeLimit && (
                <Box fontSize="xs" color="gray.500">
                  {t('vidQuestionPres.video_limited_to', {
                    minutes: timeLimit.minutes,
                    seconds: timeLimit.seconds,
                  })}
                </Box>
              )}

              {maxAttempts && totalUserAttempts < +maxAttempts && (
                <HStack
                  alignItems="center"
                  justifyContent="flex-end"
                  fontSize="xs"
                  color={labelColor}
                >
                  <Text>
                    {t('vidQuestionPres.attempt_of', {
                      attempt: totalUserAttempts + 1,
                      max: maxAttempts,
                    })}
                  </Text>

                  <Tooltip
                    hasArrow
                    placement="bottom-end"
                    label={
                      <Trans
                        i18nKey={
                          totalUserAttempts + 1 === maxAttempts
                            ? t('vidQuestionPres.last_attemps_message')
                            : t('vidQuestionPres.attempts_remaining_message')
                        }
                      />
                    }
                  >
                    <span>
                      {totalUserAttempts + 1 === maxAttempts ? (
                        <WarningTriangle />
                      ) : (
                        <QuestionCircle />
                      )}
                    </span>
                  </Tooltip>
                </HStack>
              )}
            </Flex>

            {maxAttempts && maxAttempts === totalUserAttempts && (
              <HStack
                alignItems="center"
                justifyContent="flex-end"
                fontSize="xs"
                color={labelColor}
              >
                <Text>{t('vidQuestionPres.no_attempts_left')}</Text>
              </HStack>
            )}

            {inProgressAnswers.length === 0 && isLoading && (
              <Center marginY={4}>
                <Spinner />
              </Center>
            )}

            {chooseAttempt && inProgressAnswers.length > 0 && (
              <Carousel
                items={inProgressAnswers}
                isLoading={isLoading}
                selectedAttempt={selectedAttempt}
                setSelectedAttempt={setSelectedAttempt}
              />
            )}

            {inProgressAnswers && inProgressAnswers.length > 0 && (
              <Center marginY={8}>
                <Button
                  rightIcon={<ArrowRight />}
                  isLoading={isLoading}
                  isDisabled={isPreview}
                  onClick={() => onSubmitAssessment()}
                >
                  {t('vidQuestionPres.submit_this_attempt')}
                </Button>
              </Center>
            )}

            <HtmlRenderer html={assessment.description} />
          </Box>
        )}
      </Center>
    </Box>
  );
}
