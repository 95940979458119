import { Divider, Stack } from '@chakra-ui/react';

import ContentFormTextGroup from '@/client/components/admin/create/content/text/ContentFormTextGroup';
import ContentInputGroup from '@/client/components/admin/input/ContentInputGroup';
import TinyMceEditor from '@/client/components/admin/create/shared/TinyMceEditor';
import { useTranslation } from 'react-i18next';

export default function AssessmentGeneralDetailsForm() {
  const { t } = useTranslation();

  return (
    <>
      <ContentInputGroup
        label={t('authoring.assessment.generalDetails.title.label')}
        placeholder={t('authoring.assessment.generalDetails.title.placeholder')}
        formName="title"
        isRequired
      />

      <Divider marginY={8} borderColor="neutral.100" />

      <ContentInputGroup
        label={t('authoring.assessment.generalDetails.authorName.label')}
        placeholder={t('authoring.assessment.generalDetails.authorName.placeholder')}
        formName="author"
        isRequired
      />

      <Divider marginY={8} borderColor="neutral.100" />

      <Stack spacing={6} marginY={8}>
        <ContentFormTextGroup
          label={t('authoring.assessment.generalDetails.description.label')}
          helpText={t('authoring.assessment.generalDetails.description.helpText')}
        />

        <TinyMceEditor
          isRequired
          formName="description"
          placeholder={t('authoring.assessment.generalDetails.description.placeholder')}
          label=""
          toolbarOpts="bold italic underline fontsize emoticons alignleft aligncenter alignright alignjustify outdent indent numlist bullist link removeformat undo redo"
          useMenu={false}
          minHeight={300}
        />
      </Stack>
    </>
  );
}
