import { colors } from '../colors';
import { hexToRGBA } from '@/client/utils/hexToRGBA';

const brandButton = colors.brand.button;

export const Button = {
  baseStyle: {
    _loading: {
      backgroundColor: 'brand.button',
    },
  },
  variants: {
    solid: {
      backgroundColor: 'brand.button',
      color: 'white',

      _hover: {
        backgroundColor: 'brand.button',
        opacity: 0.8,
      },
    },
    outline: {
      bgColor: 'white',
      color: 'brand.button',
      borderColor: 'brand.button',
      _hover: {
        bgColor: 'brand.button',
        color: 'white',
      },
    },
    ghost: {
      bgColor: 'transparent',
      color: 'brand.button',
      _hover: {
        bgColor: 'transparent',
        filter: 'brightness(0.8)',
      },
    },
    menu: {
      backgroundColor: 'transparent',
      borderBottom: '1px solid',
      borderBottomColor: 'blackAlpha.200',
      textAlign: 'left',
      marginBottom: 2,
      paddingY: 2,
      paddingX: 2,
      borderRadius: '0px',
      fontSize: 'sm',
      fontWeight: 'normal',
      minWidth: '200px',
      _hover: {
        backgroundColor: 'transparent',
        borderBottomColor: 'brand.primary',
      },
    },
    workshopfilter: {
      backgroundColor: '#F9F9F9',
      borderRadius: '5px',
      color: '#1A1A1A',
      fontSize: 'sm',
      fontWeight: '500',
      minWidth: '140px',
      width: { base: '100%', lg: '30%' },
      marginTop: { base: 3, lg: 0 },
      textAlign: 'left',
      overflow: 'hidden',
      _hover: {
        backgroundColor: 'blackAlpha.200',
        color: 'gray.700',
      },
    },
    pure: {
      background: 'none',
      _hover: {
        color: 'black',
        background: 'none',
      },
    },
    cancel: {
      backgroundColor: 'blackAlpha.300',
      color: 'blackAlpha.700',
      _hover: {
        backgroundColor: 'blackAlpha.500',
        color: 'gray.700',
      },
    },
    celebrate: {
      backgroundColor: 'blackAlpha.400',
      color: 'white',
      marginTop: 5,
      _hover: {
        backgroundColor: 'blackAlpha.500',
        color: 'gray.200',
      },
    },
    integrations: {
      border: '1px solid black',
      backgroundColor: 'transparent',
      display: 'inline-flex',
      padding: 1.5,
      borderRadius: 6,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: 'blackAlpha.500',
      opacity: '0.8',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: 'sm',
      _hover: {
        background: 'none',
        opacity: '1',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    adminCancel: {
      backgroundColor: 'blackAlpha.300',
      color: 'blackAlpha.700',
      borderRadius: '30px',
      _hover: {
        backgroundColor: 'blackAlpha.500',
        color: 'gray.700',
      },
    },
    adminError: {
      color: 'white',
      backgroundColor: '#D80808',
      fontWeight: 'bold',
      borderRadius: '30px',
      _loading: {
        backgroundColor: '#D80808',
      },
    },
    adminSuccess: {
      color: 'white',
      backgroundColor: 'brand.teal.100',
      fontWeight: 'bold',
      borderRadius: '30px',
    },
    adminPrimary: {
      color: 'white',
      backgroundColor: 'brand.button',
      fontWeight: 'bold',
      borderRadius: '30px',
      _loading: {
        backgroundColor: 'brand.button',
      },
      _hover: {
        _disabled: {
          backgroundColor: 'brand.button',
        },
      },
    },
    adminBrandPrimary: {
      color: 'white',
      backgroundColor: hexToRGBA(brandButton, 0.8),
      fontWeight: 'bold',
      borderRadius: '30px',
    },
    adminSecondary: {
      backgroundColor: hexToRGBA(brandButton, 0.1),
      borderRadius: '30px',
    },
    adminWarning: {
      color: 'white',
      backgroundColor: 'rgba(255, 184, 0, 1)',
      fontWeight: 'bold',
      borderRadius: '30px',
    },
    adminGhost: {
      bgColor: 'transparent',
      color: 'brand.button',
      borderRadius: '30px',
      _hover: {
        bgColor: 'transparent',
        filter: 'brightness(0.8)',
      },
    },
    icon: {
      backgroundColor: 'transparent',
      background: 'transparent',
      _hover: {
        backgroundColor: 'transparent',
        background: 'transparent',
      },
    },
    adminMenu: {
      backgroundColor: 'brand.backgroundGrey',
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'left',
      _hover: {
        backgroundColor: 'brand.backgroundGrey',
      },
    },
    createIconSolid: {
      backgroundColor: 'brand.gold.100',
      color: 'white',
      _hover: {
        backgroundColor: 'brand.gold.50',
      },
    },
    createIconOutline: {
      backgroundColor: 'white',
      color: 'brand.gold.100',
      border: '1px solid',
      borderColor: 'brand.gold.100',
      _hover: {
        backgroundColor: 'brand.gold.100',
        color: 'white',
      },
    },
    createNumberStepper: {
      background: 'white',
      border: '1px solid',
      borderColor: 'brand.gold.100',
      _disabled: {
        borderColor: 'brand.gold.50',
      },
    },
    createCancel: {
      color: 'neutral.1200',
      backgroundColor: 'neutral.50',
      borderRadius: 'md',
      fontWeight: 600,
      _hover: {
        backgroundColor: 'neutral.100',
      },
    },
    createGhost: {
      backgrounfColor: 'transparent',
      textDecoration: 'underline',
      color: hexToRGBA(colors.brand.button, 1),
      borderRadius: 'md',
      paddingY: 4,
      paddingX: 8,
      _hover: {
        backgroundColor: hexToRGBA(colors.brand.button, 0.5),
        color: 'white',
      },
    },
    createGoldOutline: {
      backgroundColor: 'transparent',
      color: 'baseBlack',
      border: '2px solid',
      borderColor: 'warmNeutral.300',
      borderRadius: 'md',
      padding: 6,
      _hover: {
        backgroundColor: 'warmNeutral.100',
      },
    },
    generateAiTags: {
      variant: 'link',
      color: 'brand.legibleBlack.100',
      fontSize: 'sm',
      fontWeight: 500,
      textDecoration: 'underline',
    },
    generateCancel: {
      variant: 'link',
      color: hexToRGBA(colors.brand.button, 1),
      fontSize: 'sm',
      fontWeight: 600,
      textDecoration: 'underline',
    },
    warmNeutralDashedBorder: {
      border: '2px dashed',
      borderColor: 'warmNeutral.200',
      borderRadius: '6px',
      backgroundColor: 'warmNeutral.0',
      paddingY: 6,
      paddingX: 8,
      color: 'warmNeutral.800',
      fontSize: '16px',
      _hover: {
        backgroundColor: 'warmNeutral.100',
      },
    },
    createNeutral: {
      paddingY: 2,
      paddingX: 3,
      borderRadius: '8px',
      backgroundColor: 'neutral.50',
      fontSize: '12px',
      fontWeight: 600,
      color: 'neutral.1200',
      _hover: {
        backgroundColor: 'neutral.100',
      },
    },
  },
};
