import { useMutation, useQuery } from '@tanstack/react-query';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import type { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';
import type { AssessmentSubmissionData } from '@/client/types/admin/content-authoring/assessments/AssessmentSubmissionData';
import AuthoringAssessmentsService from '@/client/services/api/admin/content-authoring/AuthoringAssessmentsService';
import AuthoringGraphqlService from '@/client/services/api/admin/content-authoring/AuthoringGraphqlService';
import { QuestionData } from '@/client/services/api/graphql/gql/graphql';
import { useAuthStore } from '@/client/services/state/authStore';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTrackStateStore } from '@/client/services/state/admin/create/trackStateStore';

export const useAuthorAssessment = (assessmentId: string | undefined) => {
  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const { trackState, setTrackState } = useTrackStateStore();
  const { watch } = useFormContext<AssessmentFormData>();
  const { assessmentType } = watch();

  const getAssessmentForEditor = useQuery({
    enabled: !!assessmentId,
    queryKey: ['assessment-for-editor', assessmentId, user._id],
    queryFn: () => AuthoringGraphqlService.getAssessmentForEditor(assessmentId, user._id),
  });

  const formatQuestions = (questions: QuestionData[]) => {
    let currentPage = 0;
    let currentPosition = 0;

    const formattedQuestions = questions
      .map((question) => {
        if (question.format === 'page_break') {
          currentPage++;
          currentPosition = 0;
          return null; // Skip page_break questions
        }

        const formattedQuestion = {
          page: currentPage,
          position: currentPosition,
          format: question.format,
          id: question.id,
          question: question.question,
          rubric_grading: question.rubricGrading,
          rubric_options: question.rubricOptions?.map((option) => ({
            id: option.id,
            points: option.points,
            name: option.name,
            description: option.description,
          })),
          choice_options: question.choiceOptions?.map((option) => ({
            id: option.id,
            points: option.points,
            position: option.position,
            copy: option.copy,
            image: option.image,
          })),
          question_options: question.questionOptions?.map((option) => ({
            name: option.name,
            value: option.value,
          })),
          question_asset: question.questionAsset,
          question_asset_type: question.questionAssetType,
        };

        currentPosition++;
        return formattedQuestion;
      })
      .filter((question) => question !== null);

    return formattedQuestions;
  };

  const submitAssessmentPayload: Omit<
    AssessmentSubmissionData,
    | 'approval_required'
    | 'approved'
    | 'draft'
    | '_id'
    | 'type'
    | 'author'
    | 'private'
    | 'surveys'
    | 'duration'
    | 'rating_configuration'
    | 'allow_comments'
  > = {
    // TODO: Fix this circular reference
    // @ts-ignore
    allow_buddy_grading: watch('allowBuddyGrading'),
    allow_manager_grading: watch('allowManagerGrading'),
    allow_retake: watch('allowRetake'),
    archived: watch('archived'),
    assign_buddy_grading: watch('assignBuddyGrading'),
    assign_manager_grading: watch('assignManagerGrading'),
    // TODO: Fix this circular reference
    // @ts-ignore
    assigned_graders: watch('assignedGraders')?.map((grader) => grader.id || grader._id),
    certificate: watch('certificate'),
    // TODO: Fix this circular reference
    // @ts-ignore
    certificate_data: watch('certificateData'),
    company: company._id,
    creator_name: watch('author'),
    description: watch('description'),
    explore_hide: !watch('exploreHide'),
    file_assessment: assessmentType === 'file',
    hide_cover: watch('hideCover'),
    number_of_retakes: watch('numberOfRetakes'),
    pages: watch('questions').reduce((max, question) => Math.max(max, question.page! + 1), 1),
    partner_permissions: watch('partnerPermissions'),
    percent_to_pass: watch('percentToPass'),
    questions: formatQuestions(watch('questions')),
    show_user_correct: watch('showUserCorrect'),
    show_user_correct_after_fail: watch('showUserCorrectAfterFail'),
    show_user_grade: watch('showUserGrade'),
    // TODO: Fix this circular reference
    // @ts-ignore
    tags: watch('tags'),
    title: watch('title'),
    user: user._id,
    video_assessment: assessmentType === 'video',
    linked_category: watch('linkedCategory')?.map((category) => category.id),
    privacy_collaborators: watch('privacyCollaborators')?.map((collaborator) => collaborator.id),
    privacy_locations: watch('privacyLocations')?.map((location) => location.id),
    privacy_departments: watch('privacyDepartments')?.map((department) => department.id),
    privacy_teams: watch('privacyTeams')?.map((team) => team.id),
    privacy_org_levels: watch('privacyOrgLevels')?.map((orgLevel) => orgLevel.id),
    privacy_groups: watch('privacyGroups')?.map((group) => group.id),
    privacy_grades: watch('privacyGrades')?.map((grade) => grade.id),
  };

  type SubmitAssessmentMutationVariables = Pick<
    AssessmentSubmissionData,
    'approval_required' | 'approved'
  >;

  const submitAssessmentMutation = useMutation({
    mutationFn: (variables: SubmitAssessmentMutationVariables) =>
      AuthoringAssessmentsService.submitAssessment(
        { ...submitAssessmentPayload, ...variables },
        // TODO: Fix this circular type dep
        // @ts-ignore
        watch('id'),
      ),
    onSuccess: (successData) => {
      if (watch('id')) {
        AnalyzeService.recordContentModifications({
          document: successData._id,
          type: 'assessment',
        });

        setToast({
          show: true,
          status: 'success',
          title: 'Assessment Successfully Updated',
        });
      }

      if (!watch('id')) {
        AnalyzeService.recordContentCreation({ document: successData._id, type: 'assessment' });

        setToast({
          show: true,
          status: 'success',
          title: 'Assessment Successfully Created',
        });
      }

      if (trackState) {
        setTrackState({
          ...trackState,
          contentId: successData._id,
          contentTitle: successData.title,
          returningFromContent: true,
        });

        navigate(`/admin/edit/course/track/${trackState.track}`);

        return;
      }

      window.location.replace('/pvt/#/overview/assessments');
    },
    onError: (error) => {
      console.log('Error submitting assessment: ', error);

      setToast({
        show: true,
        status: 'error',
        title: 'Error Submitting Assessment',
      });
    },
  });

  return { getAssessmentForEditor, submitAssessmentMutation, submitAssessmentPayload };
};
