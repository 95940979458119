import AssessmentAdminSettingsForm from './AssessmentAdminSettingsForm';
import AssessmentCompletionSettingsForm from './AssessmentCompletionSettingsForm';
import AssessmentGradingSettingsForm from './AssessmentGradingSettingsForm';
import ContentAuthoringBox from '@/client/components/admin/layout/ContentAuthoringBox';
import { Stack } from '@chakra-ui/react';

export default function AssessmentSettingsForm() {
  return (
    <Stack>
      <ContentAuthoringBox>
        <AssessmentCompletionSettingsForm />
      </ContentAuthoringBox>

      <ContentAuthoringBox>
        <AssessmentGradingSettingsForm />
      </ContentAuthoringBox>

      <ContentAuthoringBox>
        <AssessmentAdminSettingsForm />
      </ContentAuthoringBox>
    </Stack>
  );
}
