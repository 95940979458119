/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
import { Bell, Clock } from '@/client/components/icons/ContinuIcons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { useMutation, useQuery } from '@tanstack/react-query';

import AdminCustomTab from '@/client/components/admin/navigation/tabs/AdminCustomTab';
import AssignmentModalNotifications from './notifications/AssignmentModalNotifications';
import AssignmentScheduleForm from './AssignmentScheduleForm';
import AssignmentsService from '@/client/services/api/admin/assignments/AssignmentsService';
import { EscalationContact } from '@/client/types/admin/assignments/EscalationContact';
import { useEffect } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

// TODO: Build this out for full assignment modal
// TODO: Fix due date being short by 1 day

interface AssignmentModalProps {
  onClose: () => void;
  assignmentId: string | null;
}

export interface AssignmentModalFormType {
  due_date: boolean;
  due_in: string | number | null;
  require_recompletion: boolean;
  escalate_manager: boolean;
  escalate_buddy: boolean;
  non_reminder_messages: string[];
  reminder_messages: {
    message: string;
    time_before_due: number;
    _id: string;
  }[];
  overdue_messages: {
    message: string;
    cadence: 'daily' | 'weekly' | 'monthly';
    total_messages: number;
    total_sent: number;
    satisfied: boolean;
    _id: string;
  }[];
  escalation_contacts: EscalationContact[];
  userGroupSearchTerm: string;
}

const defaultAssignmentFormValues = {
  due_date: false,
  due_in: null,
  require_recompletion: false,
  escalate_manager: false,
  escalate_buddy: false,
  non_reminder_messages: [],
  reminder_messages: [],
  overdue_messages: [],
  escalation_contacts: [],
  userGroupSearchTerm: '',
};

export default function AssignmentModal({ onClose, assignmentId }: AssignmentModalProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();

  const methods = useForm<AssignmentModalFormType>({
    defaultValues: defaultAssignmentFormValues,
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const { data, isLoading, isError } = useQuery({
    enabled: !!assignmentId,
    queryKey: ['assignment', assignmentId],
    queryFn: () => AssignmentsService.getAssignmentById(assignmentId),
  });

  const renderNotificationsTab =
    (data?.non_reminder_messages && data?.non_reminder_messages.length) ||
    (data?.reminder_messages && data?.reminder_messages.length) ||
    (data?.overdue_messages && data?.overdue_messages.length);

  interface UpdateAssignmentPayload {
    due_date?: string;
    require_recompletion: boolean;
    escalate_manager: boolean;
    escalate_buddy: boolean;
    non_reminder_messages: string[];
    reminder_messages: {
      message: string;
      time_before_due: number;
      _id: string;
    }[];
    overdue_messages: {
      message: string;
      cadence: 'daily' | 'weekly' | 'monthly';
      total_messages: number;
      total_sent: number;
      satisfied: boolean;
      _id: string;
    }[];
    escalation_contacts: string[];
  }

  const updateAssignmentMutation = useMutation({
    mutationFn: (mutationData: UpdateAssignmentPayload) =>
      AssignmentsService.updateAssignment(assignmentId, mutationData),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('assignment.edit.success'),
      });

      onClose();
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: t('assignment.edit.error'),
      });
    },
  });

  useEffect(() => {
    if (!isError) return;

    setToast({
      show: true,
      status: 'error',
      title: t('assignments.edit.unableToFetchAssignment'),
    });

    onClose();
  }, [isError]);

  useEffect(() => {
    if (!data) return;

    reset({
      due_date: !!data.due_date,
      due_in: differenceInCalendarDays(new Date(data.due_date), new Date()),
      require_recompletion: data.require_recompletion,
      escalate_manager: data.escalate_manager,
      escalate_buddy: data.escalate_buddy,
      non_reminder_messages: data.non_reminder_messages,
      reminder_messages: data.reminder_messages,
      overdue_messages: data.overdue_messages,
      escalation_contacts: data.escalation_contacts,
    });
  }, [data]);

  const onSubmit: SubmitHandler<AssignmentModalFormType> = (submissionData) => {
    const {
      escalate_buddy,
      escalate_manager,
      escalation_contacts,
      non_reminder_messages,
      overdue_messages,
      reminder_messages,
      require_recompletion,
    } = submissionData;

    const payload: UpdateAssignmentPayload = {
      escalate_buddy,
      escalate_manager,
      escalation_contacts: escalation_contacts.map((contact) => contact._id),
      non_reminder_messages,
      overdue_messages,
      reminder_messages,
      require_recompletion,
    };

    if (submissionData.due_in) {
      payload.due_date = addDays(new Date(), +submissionData.due_in).toISOString();
    }

    updateAssignmentMutation.mutate(payload);
  };

  if (isLoading || isError) return null;

  return (
    <Modal size="full" isOpen={!!assignmentId} onClose={onClose}>
      <ModalOverlay />

      <ModalContent borderRadius="lg" margin={50}>
        <ModalCloseButton />

        <ModalBody padding={8}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Text fontWeight="medium" fontSize="2xl">
                {t('assignments.edit.assignmentSettings')}
              </Text>

              <Tabs isLazy>
                <TabList justifyContent="center">
                  <AdminCustomTab>
                    <VStack color="brand.button">
                      <Clock boxSize={5} />

                      <Text fontWeight="medium">{t('assignments.edit.dueDate')}</Text>
                    </VStack>
                  </AdminCustomTab>

                  {renderNotificationsTab && (
                    <AdminCustomTab>
                      <VStack color="brand.button">
                        <Bell boxSize={5} />

                        <Text fontWeight="medium">{t('assignments.edit.notifications')}</Text>
                      </VStack>
                    </AdminCustomTab>
                  )}
                </TabList>

                <Box backgroundColor="brand.mist" marginY={6} paddingY={6}>
                  <VStack spacing={4}>
                    <Text>
                      {t('assignments.edit.assigning')}{' '}
                      <Text as="b">{data.assigned_content.length} </Text>{' '}
                      {t('assignments.edit.itemsTo')}
                      <Text as="b">1</Text> {t('assignments.edit.users')}
                    </Text>

                    <HStack>
                      {data.assigned_content.map((content) => (
                        <Tag variant="admin" key={content.content_id._id}>
                          <TagLabel>{content.content_id.title}</TagLabel>
                        </Tag>
                      ))}
                    </HStack>
                  </VStack>
                </Box>

                <Flex width="full" justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="adminPrimary"
                    size="xs"
                    isLoading={updateAssignmentMutation.isLoading}
                  >
                    {t('assignments.edit.update')}
                  </Button>
                </Flex>

                <TabPanels>
                  <TabPanel>
                    <AssignmentScheduleForm />
                  </TabPanel>

                  {renderNotificationsTab && (
                    <TabPanel>
                      <AssignmentModalNotifications
                        assignment={data}
                        onSubmit={handleSubmit(onSubmit)}
                      />
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
