import { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';
import MediaResponseQuestionBuilder from './question-builders/MediaResponseQuestionBuilder';
import TextResponseQuestionBuilder from './question-builders/text-response-assessment/TextResponseQuestionBuilder';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export default function AssessmentQuestionForm() {
  const { id } = useParams();
  const { tabs, setTabs } = useCreateStore();
  const { watch } = useFormContext<AssessmentFormData>();
  const { assessmentType } = watch();

  useEffect(() => {
    if (tabs) {
      const updatedTabs = tabs.map((tab, index) => ({
        ...tab,
        isDisabled: id ? false : index > 1 ? true : tab.isDisabled,
      }));
      setTabs(updatedTabs);
    }
  }, []);

  return (
    <>
      {(assessmentType === 'video' || assessmentType === 'file') && (
        <MediaResponseQuestionBuilder />
      )}

      {assessmentType === 'text' && <TextResponseQuestionBuilder />}
    </>
  );
}
