import { create } from 'zustand';

type ContentType =
  | 'article'
  | 'file'
  | 'video'
  | 'scorm'
  | 'product'
  | 'track'
  | 'udemy'
  | 'linkedin'
  | 'masterclass'
  | 'assessment';

type Tab = {
  label: string;
  content: JSX.Element;
  isDisabled: boolean;
};
interface CreateState {
  tabs: Tab[];
  setTabs: (tabs: Tab[]) => void;
  contentType: ContentType;
  setContentType: (contentType: ContentType) => void;
  contentTitle: string;
  setContentTitle: (contentTitle: string) => void;
  previewFullscreen: boolean;
  setPreviewFullscreen: (previewFullscreen: boolean) => void;
  tabIndex: number;
  setTabIndex: (tabIndex: number) => void;
  canAdvance: boolean;
  setCanAdvance: (canAdvance: boolean) => void;
  duplicateModalIsOpen: boolean;
  setDuplicateModalIsOpen: (duplicateModalIsOpen: boolean) => void;
  discardModalIsOpen: boolean;
  setDiscardModalIsOpen: (discardModalIsOpen: boolean) => void;
  noImageModalIsOpen: boolean;
  setNoImageModalIsOpen: (noImageModalIsOpen: boolean) => void;
  coursePublishModalIsOpen: boolean;
  setCoursePublishModalIsOpen: (coursePublishModalIsOpen: boolean) => void;
  publishModalIsOpen: boolean;
  setPublishModalIsOpen: (publishModalIsOpen: boolean) => void;
  archiveModalIsOpen: boolean;
  setArchiveModalIsOpen: (archiveModalIsOpen: boolean) => void;
  collaboratorSaveModalIsOpen: boolean;
  setCollaboratorSaveModalIsOpen: (collaboratorSaveModalIsOpen: boolean) => void;
  canLeave: boolean;
  setCanLeave: (canLeave: boolean) => void;
  uploadStatus: 'idle' | 'loading' | 'success' | 'error';
  setUploadStatus: (uploadStatus: 'idle' | 'loading' | 'success' | 'error') => void;
  tinyMceInFullscreenMode: boolean;
  setTinyMceInFullscreenMode: (tinyMceInFullscreenMode: boolean) => void;
  selectedSectionIndex: number;
  setSelectedSectionIndex: (selectedSectionIndex: number) => void;
  instructorDrawerIsOpen: boolean;
  setInstructorDrawerIsOpen: (instructorDrawerIsOpen: boolean) => void;
  imagesContainerRef: React.RefObject<HTMLDivElement>;
  setImagesContainerRef: (imagesContainerRef: React.RefObject<HTMLDivElement>) => void;
  coursePreviewModalIsOpen: boolean;
  setCoursePreviewModalIsOpen: (coursePreviewModalIsOpen: boolean) => void;
  renderErrors: boolean;
  setRenderErrors: (renderErrors: boolean) => void;
  assessmentPreviewModalIsOpen: boolean;
  setAssessmentPreviewModalIsOpen: (assessmentPreviewModalIsOpen: boolean) => void;
}

export const useCreateStore = create<CreateState>((set) => ({
  tabs: [],
  setTabs: (tabs) => set({ tabs }),
  contentTitle: '',
  setContentTitle: (contentTitle) => set({ contentTitle }),
  contentType: 'article',
  setContentType: (contentType) => set({ contentType }),
  previewFullscreen: false,
  setPreviewFullscreen: (previewFullscreen) => set({ previewFullscreen }),
  tabIndex: 0,
  setTabIndex: (tabIndex) => set({ tabIndex }),
  canAdvance: false,
  setCanAdvance: (canAdvance) => set({ canAdvance }),
  duplicateModalIsOpen: false,
  setDuplicateModalIsOpen: (duplicateModalIsOpen) => set({ duplicateModalIsOpen }),
  discardModalIsOpen: false,
  setDiscardModalIsOpen: (discardModalIsOpen) => set({ discardModalIsOpen }),
  noImageModalIsOpen: false,
  setNoImageModalIsOpen: (noImageModalIsOpen) => set({ noImageModalIsOpen }),
  coursePublishModalIsOpen: false,
  setCoursePublishModalIsOpen: (coursePublishModalIsOpen) => set({ coursePublishModalIsOpen }),
  publishModalIsOpen: false,
  setPublishModalIsOpen: (publishModalIsOpen) => set({ publishModalIsOpen }),
  archiveModalIsOpen: false,
  setArchiveModalIsOpen: (archiveModalIsOpen) => set({ archiveModalIsOpen }),
  collaboratorSaveModalIsOpen: false,
  setCollaboratorSaveModalIsOpen: (collaboratorSaveModalIsOpen) =>
    set({ collaboratorSaveModalIsOpen }),
  canLeave: false,
  setCanLeave: (canLeave) => set({ canLeave }),
  uploadStatus: 'idle',
  setUploadStatus: (uploadStatus) => set({ uploadStatus }),
  tinyMceInFullscreenMode: false,
  setTinyMceInFullscreenMode: (tinyMceInFullscreenMode) => set({ tinyMceInFullscreenMode }),
  selectedSectionIndex: 0,
  setSelectedSectionIndex: (selectedSectionIndex) => set({ selectedSectionIndex }),
  instructorDrawerIsOpen: false,
  setInstructorDrawerIsOpen: (instructorDrawerIsOpen) => set({ instructorDrawerIsOpen }),
  imagesContainerRef: { current: null },
  setImagesContainerRef: (imagesContainerRef) => set({ imagesContainerRef }),
  coursePreviewModalIsOpen: false,
  setCoursePreviewModalIsOpen: (coursePreviewModalIsOpen) => set({ coursePreviewModalIsOpen }),
  renderErrors: false,
  setRenderErrors: (renderErrors) => set({ renderErrors }),
  assessmentPreviewModalIsOpen: false,
  setAssessmentPreviewModalIsOpen: (assessmentPreviewModalIsOpen) => set({ assessmentPreviewModalIsOpen})
}));
