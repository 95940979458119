import {
  type ArticleExtras,
  type Content,
  type FileExtras,
  type ScormExtras,
  type TrackExtras,
  type VideoExtras,
} from '@/client/services/api/graphql/gql/graphql';
import { Divider, Fade, Grid, GridItem, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import ContentLength from '@/client/components/data-display/content/ContentLength';
import type { ReactNode } from 'react';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AdminSettingsPreview } from './settings/AdminSettingsPreview';
import EditSectionButton from './settings/EditSectionButton';

interface PublishDetailsSectionProps {
  label: string;
  infoComponent: ReactNode;
  onEdit: () => void;
}

function PublishDetailsSection({ label, infoComponent, onEdit }: PublishDetailsSectionProps) {
  return (
    <Grid templateColumns="repeat(3, 1fr)">
      <GridItem>
        <Text variant="createLabel">{label}</Text>
      </GridItem>

      <GridItem>{infoComponent}</GridItem>

      <GridItem display="flex" justifyContent="end" alignItems="center">
        <EditSectionButton
          onEdit={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            onEdit();
          }}
        />
      </GridItem>
    </Grid>
  );
}

export default function ContentPublishForm() {
  const { t } = useTranslation();
  const { setTabIndex, contentType } = useCreateStore();
  const { watch } = useFormContext<
    Content & ArticleExtras & FileExtras & ScormExtras & VideoExtras & TrackExtras
  >();

  const hasBannerImage = watch('bannerImage');
  const hasThumbnailImage = watch('image');
  const selectedCategories = watch('linkedCategory');
  const allowComments = watch('allowComments');
  const selectedSurvey = watch('surveys');
  const ratingsEnabled = watch('ratingConfiguration.allowRating');
  const allowFeedback = watch('ratingConfiguration.allowFeedback');
  const showRating = watch('ratingConfiguration.showUsersRating');
  const notifyContacts = watch('ratingConfiguration.notifyFeedbackOnly');
  const individualNotifications = watch('ratingConfiguration.messaging.individuals');
  const slackChannelNotifications = watch('ratingConfiguration.messaging.slackChannels');
  const author = watch('author');
  const instructors = watch('instructors');

  const isTrack = contentType === 'track';

  const isImportedContent =
    contentType === 'udemy' || contentType === 'masterclass' || contentType === 'linkedin';

  return (
    <Fade in delay={0.1}>
      <Stack spacing={6}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem wordBreak="break-all" paddingRight={6}>
            <Stack spacing={2}>
              <Text variant="createLabel">{t('authoring.publishPreview.title')}</Text>

              <Text variant="createHelpText">{watch('title')}</Text>
            </Stack>
          </GridItem>

          <GridItem>
            <Stack spacing={4}>
              <Stack spacing={2}>
                <Text variant="createLabel">
                  {isTrack || isImportedContent
                    ? t('contentViews.tracks.instructor')
                    : t('authoring.publishPreview.author')}
                </Text>

                <Text variant="createHelpText">
                  {isTrack || isImportedContent ? instructors[0]?.name : author}
                </Text>
              </Stack>

              <Stack spacing={2}>
                <Text variant="createLabel">{t('authoring.publishPreview.duration')}</Text>

                <ContentLength
                  renderIcon={false}
                  duration={watch('duration') || 0}
                  color="brand.legibleBlack.60"
                  textVariant="createLabel"
                  size="sm"
                  fontWeight={400}
                />
              </Stack>
            </Stack>
          </GridItem>

          <GridItem display="flex" justifyContent="end" alignItems="center">
            <EditSectionButton
              onEdit={() => (isImportedContent ? setTabIndex(1) : setTabIndex(0))}
            />
          </GridItem>
        </Grid>

        <Divider variant="create" />

        <PublishDetailsSection
          label={t('authoring.publishPreview.images')}
          infoComponent={
            <Stack>
              {contentType !== 'video' && contentType !== 'file' && contentType !== 'scorm' && (
                <HStack>
                  <Icon
                    color={hasBannerImage ? 'brand.gold.100' : 'red'}
                    as={hasBannerImage ? FaCheckCircle : FaTimesCircle}
                  />

                  <Text variant="createLabel">{t('authoring.publishPreview.bannerImage')}</Text>
                </HStack>
              )}

              <HStack>
                <Icon
                  color={hasThumbnailImage ? 'brand.gold.100' : 'red'}
                  as={hasThumbnailImage ? FaCheckCircle : FaTimesCircle}
                />

                <Text variant="createLabel">{t('authoring.publishPreview.thumbnailImage')}</Text>
              </HStack>
            </Stack>
          }
          onEdit={() => setTabIndex(isTrack ? 0 : isImportedContent ? 1 : 0)}
        />

        <Divider variant="create" />

        <AdminSettingsPreview onEdit={() => setTabIndex(contentType === 'track' ? 2 : 1)} />

        <Divider variant="create" />

        <PublishDetailsSection
          label={t('authoring.publishPreview.exploreSettings')}
          infoComponent={
            <Stack>
              <Text variant="createLabel">{t('authoring.publishPreview.explore')}</Text>

              <Text variant="createHelpText">
                {selectedCategories && selectedCategories.length > 0
                  ? `${t('authoring.publishPreview.shownIn')}: ${selectedCategories
                      .map((category) => category.name)
                      .join(', ')}`
                  : t('authoring.publishPreview.noCategoriesSelected')}
              </Text>
            </Stack>
          }
          onEdit={() => setTabIndex(contentType === 'track' ? 2 : 1)}
        />

        <Divider variant="create" />

        <PublishDetailsSection
          label={t('authoring.publishPreview.interactionSettings')}
          infoComponent={
            <Stack spacing={4}>
              <Stack spacing={2}>
                <Text variant="createLabel">{t('authoring.publishPreview.ratings')}</Text>

                <Text variant="createHelpText">
                  {ratingsEnabled
                    ? t('authoring.publushPreview.ratingsEnabled')
                    : t('authoring.publishPreview.ratingsDisabled')}
                </Text>

                {showRating && (
                  <Text variant="createHelpText">
                    {t('authoring.publishPreview.ratingsVisible')}
                  </Text>
                )}

                {allowFeedback &&
                  notifyContacts &&
                  (individualNotifications.length > 0 || slackChannelNotifications.length > 0) && (
                    <Text variant="createHelpText">
                      {t('authoring.publishPreview.feedbackSentTo')}
                      {individualNotifications &&
                        individualNotifications.length > 0 &&
                        individualNotifications.map((individual) => individual.name).join(', ')}
                      {slackChannelNotifications &&
                        slackChannelNotifications.length > 0 &&
                        slackChannelNotifications.map((channel: any) => channel.name).join(', ')}
                    </Text>
                  )}
              </Stack>

              <Stack spacing={2}>
                <Text variant="createLabel">{t('authoring.publishPreview.comments')}</Text>

                <Text variant="createHelpText">
                  {allowComments
                    ? t('authoring.publishPreview.commentsEnabled')
                    : t('authoring.publishPreview.commentsDisabled')}
                </Text>
              </Stack>

              <Stack spacing={2}>
                <Text variant="createLabel">{t('authoring.publishPreview.survey')}</Text>

                <Text variant="createHelpText">
                  {selectedSurvey && selectedSurvey.length > 0
                    ? selectedSurvey[0].title
                    : t('authoring.publishPreview.noSurveysSelected')}
                </Text>
              </Stack>
            </Stack>
          }
          onEdit={() => setTabIndex(contentType === 'track' ? 3 : 2)}
        />
      </Stack>
    </Fade>
  );
}
