import { Box, HStack } from '@chakra-ui/react';

import AssessmentCheckbox from '../../../input/AssessmentCheckbox';
import { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';
import ContentFormTextGroup from '../../../text/ContentFormTextGroup';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function FileResponseTypeForm() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<AssessmentFormData>();

  const questions = watch('questions');

  const fileResponseType =
    questions[0]?.questionOptions?.find((option) => option.name === 'upload_type')?.value ||
    undefined;

  const handleResponsetypeChange = (value: string, isChecked: boolean) => {
    const question = questions[0];

    const uploadTypes = question?.questionOptions?.find(
      (option) => option.name === 'upload_type',
    )?.value;

    let uploadTypeList =
      typeof uploadTypes === 'string' ? uploadTypes.split(',').filter(Boolean) : [];

    if (isChecked) {
      uploadTypeList = [...uploadTypeList, value];
    } else {
      uploadTypeList = uploadTypeList.filter((type: string) => type !== value);
    }

    const updatedUploadTypes = uploadTypeList.join(',');

    const updatedQuestion = {
      ...question,
      questionOptions: question?.questionOptions?.map((option) => {
        if (option.name === 'upload_type') {
          return { ...option, value: updatedUploadTypes };
        }
        return option;
      }),
    };
    // @ts-ignore
    setValue('questions', [updatedQuestion]);
  };

  const fileTypeOptions = [
    {
      label: t('authoring.assessment.fileResponseType.pdf'),
      value: 'pdf',
    },
    {
      label: t('authoring.assessment.fileResponseType.jpeg'),
      value: 'jpeg',
    },
    {
      label: t('authoring.assessment.fileResponseType.png'),
      value: 'png',
    },
    {
      label: t('authoring.assessment.fileResponseType.xls'),
      value: 'xls',
    },
    {
      label: t('authoring.assessment.fileResponseType.doc'),
      value: 'doc',
    },
  ];

  return (
    <>
      <Box paddingY={8}>
        <ContentFormTextGroup
          label={t('authoring.assessment.fileResponseType.label')}
          helpText={t('authoring.assessment.fileResponseType.helpText')}
        />
      </Box>

      <HStack spacing={2} width="100%">
        {fileTypeOptions.map((option) => (
          <AssessmentCheckbox
            name="questions"
            label={option.label}
            isChecked={
              typeof fileResponseType === 'string' && fileResponseType.includes(option.value)
            }
            value={option.value}
            onHandleChange={handleResponsetypeChange}
          />
        ))}
      </HStack>
    </>
  );
}
