import { Box, Divider, Text } from '@chakra-ui/react';

import AuthoringCollaboratorSearch from '../settings/AuthoringCollaboratorSearch';
import AuthoringSegmentationSearch from '../audience/AuthoringSegmentationSearch';
import AuthoringTagInput from '../../input/AuthoringTagInput';
import ContentExtendAccessForm from '../audience/ContentExtendAccessForm';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useAuthStore } from '@/client/services/state/authStore';
import { useTranslation } from 'react-i18next';

export default function AssessmentAdminSettingsForm() {
  const { t } = useTranslation();
  const { isAllowed } = useAccessHelper();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const allowance = {
    add_partner:
      company.feature_flags.enable_partners && isAllowed(['admin', 'content'], null, ['admin']),
  };
  return (
    <>
      <Box paddingY={4}>
        <Text variant="createHeading">{t('authoring.assessment.adminSettings')}</Text>
      </Box>

      <AuthoringSegmentationSearch />

      <Divider marginBottom={4} borderColor="neutral.100" />

      <AuthoringCollaboratorSearch />

      <Divider marginBottom={4} borderColor="neutral.100" />

      <AuthoringTagInput />

      <Divider marginBottom={4} borderColor="neutral.100" />

      {allowance.add_partner && <ContentExtendAccessForm />}
    </>
  );
}
