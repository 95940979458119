import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

type ContentAuthoringBoxProps = {
  children: ReactNode;
};

export default function ContentAuthoringBox({ children }: ContentAuthoringBoxProps) {
  return (
    <Box
      paddingY={12}
      paddingX={10}
      backgroundColor="white"
      borderRadius="24px"
      border="1px solid"
      borderColor="neutral.100"
    >
      {children}
    </Box>
  );
}
