import { Controller, useFormContext } from 'react-hook-form';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { HStack, IconButton, Input, Stack, Text, useNumberInput } from '@chakra-ui/react';

/* eslint-disable react/jsx-props-no-spreading */
import { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';
import { DefaultTFuncReturn } from 'i18next';

interface AssessmentCounterProps {
  counterMin: number;
  counterMax: number;
  name: any;
  option: string;
  onHandleChange: (value: number, option: string) => void;
  label?: string | DefaultTFuncReturn;
  inputWidth?: number;
  buttonSize?: 'xs' | 'sm' | 'md' | 'lg';
}

export default function AssessmentCounter({
  counterMin,
  counterMax,
  name,
  option,
  label,
  onHandleChange,
  inputWidth = 14,
  buttonSize = 'sm',
}: AssessmentCounterProps) {
  const { control } = useFormContext<AssessmentFormData>();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
          defaultValue: 1,
          min: counterMin,
          max: counterMax,
          onChange: (value) => {
            const valInt = parseInt(value, 10);
            onHandleChange(valInt, option);
            field.onChange(valInt);
          },
        });

        const increase = getIncrementButtonProps();
        const decrease = getDecrementButtonProps();
        const input = getInputProps();
        return (
          <HStack flex={1}>
            <Stack>
              <HStack>
                {/* @ts-ignore */}
                <IconButton
                  size={buttonSize}
                  variant="createNumberStepper"
                  {...decrease}
                  isRound
                  icon={<FaMinus />}
                />

                <Input
                  {...input}
                  type="number"
                  variant="createNumber"
                  fontSize="14px"
                  fontWeight={600}
                  width={inputWidth}
                  color={input['aria-valuenow'] === 0 ? 'neutral.300' : 'baseBlack'}
                  value={field.value || 1}
                />

                {/* @ts-ignore */}
                <IconButton
                  size={buttonSize}
                  variant="createNumberStepper"
                  isRound
                  icon={<FaPlus />}
                  {...increase}
                />
              </HStack>

              {label && (
                <Text
                  fontSize="10px"
                  textAlign="center"
                  color="neutral.700"
                  fontWeight={600}
                  textTransform="uppercase"
                >
                  {label}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      }}
    />
  );
}
