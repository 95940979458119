import { Divider, Fade, Grid, GridItem, HStack, Stack, Text } from '@chakra-ui/react';

import { AdminSettingsPreview } from '../settings/AdminSettingsPreview';
import { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';
import ContentAuthoringBox from '@/client/components/admin/layout/ContentAuthoringBox';
import { ContentFormValues } from '@/client/types/admin/content-authoring/ContentFormValues';
import EditSectionButton from '../settings/EditSectionButton';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface PreviewIconProps {
  value: boolean;
}

function PreviewIcon({ value }: PreviewIconProps) {
  return value ? (
    <FaCheck style={{ fontSize: '14px' }} />
  ) : (
    <ImCross style={{ fontSize: '12px' }} />
  );
}

export default function AssessmentPublishForm() {
  const { t } = useTranslation();
  const { setTabIndex } = useCreateStore();
  const { watch } = useFormContext<AssessmentFormData & ContentFormValues>();
  const {
    title,
    author,
    questions,
    percentToPass,
    numberOfRetakes,
    showUserGrade,
    showUserCorrect,
    showUserCorrectAfterFail,
    certificate,
    allowManagerGrading,
    allowBuddyGrading,
    enable_additional_graders: enableAdditionalGraders,
  } = watch();

  const assessmentType = watch('assessmentType')
    ? watch('assessmentType').charAt(0).toUpperCase() + watch('assessmentType').slice(1)
    : null;

  let videoResponseType =
    (questions &&
      questions[0]?.questionOptions?.find((option) => option.name === 'recording_type')?.value) ||
    undefined;

  videoResponseType =
    videoResponseType === 'web_cam_video'
      ? 'Webcam'
      : videoResponseType === 'screen_voice'
      ? 'Screen Recording'
      : undefined;

  const fileResponseType =
    (questions &&
      questions[0]?.questionOptions?.find((option) => option.name === 'upload_type')?.value) ||
    undefined;

  const recordingLimit =
    (questions &&
      questions[0]?.questionOptions?.find((option) => option.name === 'recording_limit')?.value) ||
    1;

  const maxAttempt =
    (questions &&
      questions[0]?.questionOptions?.find((option) => option.name === 'max_attempts')?.value) ||
    1;

  const allowUpload =
    (questions &&
      questions[0]?.questionOptions?.find((option) => option.name === 'allow_upload')?.value) ||
    false;

  const showAttempt =
    (questions &&
      questions[0]?.questionOptions?.find((option) => option.name === 'choose_attempt')?.value) ||
    false;

  const rubricGrading = (questions && questions[0]?.rubricGrading) || false;

  const totalCriteriaGradingPoints =
    questions && questions[0]?.rubricOptions?.reduce((acc, option) => acc + option.points!, 0);

  const totalOpenGradingPoints =
    questions &&
    questions[0]?.questionOptions?.find((option) => option.name === 'manual_points')?.value;

  const totalMC =
    (questions && questions?.filter((question) => question.format === 'multiple_choice').length) ||
    0;

  const totalShortAnswer =
    (questions && questions?.filter((question) => question.format === 'short_input').length) || 0;

  const totalLongAnswer =
    (questions && questions?.filter((question) => question.format === 'long_input').length) || 0;

  const totalDropDown =
    (questions && questions?.filter((question) => question.format === 'dropdown').length) || 0;

  const completionSettings = [
    {
      value: showUserGrade,
      label: t('assessment.publishPreview.showPassGrade'),
    },
    {
      value: showUserCorrect,
      label: t('assessment.publishPreview.showUserCorrect'),
    },
    {
      value: showUserCorrectAfterFail,
      label: t('assessment.publishPreview.showCorrectAfterFail'),
    },
    {
      value: certificate,
      label: t('assessment.publishPreview.enableCertificate'),
    },
  ];

  const gradingSettings = [
    {
      value: allowManagerGrading,
      label: t('assessment.publishPreview.allowManagerGrading'),
    },
    {
      value: allowBuddyGrading,
      label: t('assessment.publishPreview.allowBuddyGrading'),
    },
    {
      value: enableAdditionalGraders,
      label: t('assessment.publishPreview.allowAdditionalGraders'),
    },
  ];

  const questionTypes = [
    {
      value: totalMC,
      label: t('assessment.publishPreview.questionTypes.mc'),
    },
    {
      value: totalShortAnswer,
      label: t('assessment.publishPreview.questionTypes.shortAnswer'),
    },
    {
      value: totalLongAnswer,
      label: t('assessment.publishPreview.questionTypes.longAnswer'),
    },
    {
      value: totalDropDown,
      label: t('assessment.publishPreview.questionTypes.dropdown'),
    },
  ];

  return (
    <ContentAuthoringBox>
      <Text variant="createHeading">{t('assessment.publishPreview.title')}</Text>

      <br />

      <Fade in delay={0.1}>
        <Stack spacing={6}>
          <Grid templateColumns="repeat(3, 1fr)">
            <GridItem wordBreak="break-all" paddingRight={6}>
              <Stack spacing={2}>
                <Text variant="createLabel">{t('authoring.publishPreview.title')}</Text>

                <Text variant="createHelpText" wordBreak="break-word">
                  {title}
                </Text>
              </Stack>
            </GridItem>

            <GridItem>
              <Stack spacing={4}>
                <Stack spacing={2}>
                  <Text variant="createLabel">{t('authoring.publishPreview.author')}</Text>

                  <Text variant="createHelpText">{author}</Text>
                </Stack>

                <Stack spacing={2}>
                  <Text variant="createLabel">{t('assessment.publishPreview.responseType')}</Text>

                  <Text variant="createHelpText">
                    {assessmentType === 'Text' ? (
                      t('assessment.publishPreview.responseType.text')
                    ) : (
                      <>
                        {' '}
                        {assessmentType} -{' '}
                        {assessmentType === 'Video' ? videoResponseType : fileResponseType}{' '}
                      </>
                    )}
                  </Text>
                </Stack>
              </Stack>
            </GridItem>

            <GridItem display="flex" justifyContent="end" alignItems="center">
              <EditSectionButton onEdit={() => setTabIndex(0)} />
            </GridItem>
          </Grid>

          <Divider variant="create" />

          {assessmentType === 'Video' && (
            <>
              <Grid templateColumns="repeat(3, 1fr)">
                <GridItem wordBreak="break-all" paddingRight={6}>
                  <Stack spacing={2}>
                    <Text variant="createLabel">
                      {t('assessment.publishPreview.responseSettings')}
                    </Text>
                  </Stack>
                </GridItem>

                <GridItem>
                  <Stack spacing={4}>
                    <Stack spacing={2}>
                      <Text variant="createLabel">{t('assessment.publishPreview.timeLimit')}</Text>

                      <Text variant="createHelpText">
                        {recordingLimit}{' '}
                        {recordingLimit && (recordingLimit as number) > 1 ? 'mins' : 'min'}
                      </Text>
                    </Stack>

                    <Stack spacing={2}>
                      <Text variant="createLabel">{t('assessment.publishPreview.maxAttemps')}</Text>

                      <Text variant="createHelpText">
                        {maxAttempt}{' '}
                        {maxAttempt && (maxAttempt as number) > 1 ? 'attempts' : 'attempt'}
                      </Text>
                    </Stack>

                    <HStack spacing={2}>
                      <PreviewIcon value={allowUpload as boolean} />

                      <Text variant="createLabel" fontWeight={500}>
                        {t('assessment.publishPreview.allowUpload')}
                      </Text>
                    </HStack>

                    <HStack spacing={2}>
                      <PreviewIcon value={showAttempt as boolean} />

                      <Text variant="createLabel" fontWeight={500}>
                        {t('assessment.publishPreview.showAttempt')}
                      </Text>
                    </HStack>
                  </Stack>
                </GridItem>

                <GridItem display="flex" justifyContent="end" alignItems="center">
                  <EditSectionButton onEdit={() => setTabIndex(1)} />
                </GridItem>
              </Grid>

              <Divider variant="create" />
            </>
          )}

          {(assessmentType === 'File' || assessmentType === 'Video') && (
            <>
              <Grid templateColumns="repeat(5, 1fr)">
                <GridItem wordBreak="break-all" paddingRight={6} colSpan={4}>
                  <Stack spacing={2}>
                    <Text variant="createLabel">
                      {t('assessment.publishPreview.questionPrompt')}
                    </Text>

                    <Text variant="createHelpText">{questions[0].question}</Text>
                  </Stack>
                </GridItem>

                <GridItem display="flex" justifyContent="end" alignItems="center" colSpan={1}>
                  <EditSectionButton onEdit={() => setTabIndex(1)} />
                </GridItem>
              </Grid>

              <Divider variant="create" />
            </>
          )}

          {assessmentType === 'Text' && (
            <>
              <Grid templateColumns="repeat(6, 1fr)">
                <GridItem wordBreak="break-all" paddingRight={6} colSpan={2}>
                  <Stack spacing={2}>
                    <Text variant="createLabel">
                      {t('assessment.publishPreview.questionTypes')}
                    </Text>
                  </Stack>
                </GridItem>

                <GridItem colSpan={3}>
                  <Stack spacing={4}>
                    {questionTypes.map((questionType) => (
                      <Stack key={questionType.value} spacing={2}>
                        <Text variant="createLabel">{questionType.label}</Text>

                        <Text variant="createHelpText">
                          {questionType.value} {questionType.value > 1 ? 'questions' : 'question'}
                        </Text>
                      </Stack>
                    ))}
                  </Stack>
                </GridItem>

                <GridItem display="flex" justifyContent="end" alignItems="center" colSpan={1}>
                  <EditSectionButton onEdit={() => setTabIndex(1)} />
                </GridItem>
              </Grid>

              <Divider variant="create" />
            </>
          )}

          <Grid templateColumns="repeat(6, 1fr)">
            <GridItem wordBreak="break-all" paddingRight={6} colSpan={2}>
              <Stack spacing={2}>
                <Text variant="createLabel">
                  {t('assessment.publishPreview.completionSettings')}
                </Text>
              </Stack>
            </GridItem>

            <GridItem colSpan={3}>
              <Stack spacing={4}>
                <Stack spacing={2}>
                  <Text variant="createLabel">
                    {t('assessment.publishPreview.minPassingGrade')}
                  </Text>

                  <Text variant="createHelpText">{percentToPass}%</Text>
                </Stack>

                <Stack spacing={2}>
                  <Text variant="createLabel">{t('assessment.publishPreview.numRetakes')}</Text>

                  <Text variant="createHelpText">
                    {numberOfRetakes}{' '}
                    {(numberOfRetakes as number) > 1 || (numberOfRetakes as number) === 0
                      ? 'retakes'
                      : 'retake'}
                  </Text>
                </Stack>

                {completionSettings.map((setting) => (
                  <HStack key={setting.label} spacing={2}>
                    <PreviewIcon value={setting.value as boolean} />

                    <Text variant="createLabel" fontWeight={500}>
                      {setting.label}
                    </Text>
                  </HStack>
                ))}
              </Stack>
            </GridItem>

            <GridItem display="flex" justifyContent="end" alignItems="center" colSpan={1}>
              <EditSectionButton onEdit={() => setTabIndex(2)} />
            </GridItem>
          </Grid>

          <Divider variant="create" />

          <Grid templateColumns="repeat(6, 1fr)">
            <GridItem wordBreak="break-all" paddingRight={6} colSpan={2}>
              <Stack spacing={2}>
                <Text variant="createLabel">{t('authoring.assessment.gradingSettings')}</Text>
              </Stack>
            </GridItem>

            <GridItem colSpan={3}>
              <Stack spacing={4}>
                {assessmentType !== 'Text' && (
                  <>
                    <Stack spacing={2}>
                      <Text variant="createLabel">
                        {t('assessment.publishPreview.gradingType')}
                      </Text>

                      <Text variant="createHelpText">
                        {rubricGrading
                          ? t('assessment.publishPreview.gradingCriteria')
                          : t('assessment.publishPreview.openGrading')}
                      </Text>
                    </Stack>

                    <Stack spacing={2}>
                      <Text variant="createLabel">{t('assessment.publishPreview.points')}</Text>

                      <Text variant="createHelpText">
                        {rubricGrading ? totalCriteriaGradingPoints : totalOpenGradingPoints}
                      </Text>
                    </Stack>
                  </>
                )}

                {gradingSettings.map((setting) => (
                  <HStack key={setting.label} spacing={2}>
                    <PreviewIcon value={setting.value as boolean} />

                    <Text variant="createLabel" fontWeight={500}>
                      {setting.label}
                    </Text>
                  </HStack>
                ))}
              </Stack>
            </GridItem>

            <GridItem display="flex" justifyContent="end" alignItems="center" colSpan={1}>
              <EditSectionButton onEdit={() => setTabIndex(1)} />
            </GridItem>
          </Grid>

          <Divider variant="create" />

          <AdminSettingsPreview onEdit={() => setTabIndex(2)} />
        </Stack>
      </Fade>
    </ContentAuthoringBox>
  );
}
