import {
  ArticleExtras,
  AssessmentExtras,
  Content,
  ContentTypes,
  FileExtras,
  ScormExtras,
  TrackExtras,
  VideoExtras,
} from '@/client/services/api/graphql/gql/graphql';
import { Grid, GridItem, Stack, Text } from '@chakra-ui/react';

import EditSectionButton from './EditSectionButton';
import SegmentationService from '@/client/services/api/admin/segmentation/SegmentationService';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface AdminSettingsPreviewProps {
  onEdit: () => void;
}

export function AdminSettingsPreview({ onEdit }: AdminSettingsPreviewProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext<
    Content &
      ArticleExtras &
      FileExtras &
      ScormExtras &
      VideoExtras &
      TrackExtras &
      AssessmentExtras
  >();
  const locations = watch('privacyLocations') || [];
  const locationIds = locations.map((location) => location.id) as string[];
  const departments = watch('privacyDepartments') || [];
  const departmentIds = departments.map((department) => department.id) as string[];
  const teams = watch('privacyTeams') || [];
  const teamIds = teams.map((team) => team.id) as string[];
  const orgLevels = watch('privacyOrgLevels') || [];
  const orgLevelIds = orgLevels.map((orgLevel) => orgLevel.id) as string[];
  const grades = watch('privacyGrades') || [];
  const gradeIds = grades.map((grade) => grade.id) as string[];
  const groups = watch('privacyGroups') || [];
  const groupIds = groups.map((group) => group.id) as string[];
  const partnerSelection = watch('partnerPermissions');
  const partnerValueMap: { [key: string]: string } = {
    TENANT: t('authoring.partnerValueMap.tenant'),
    PARTNER: t('authoring.partnerValueMap.partner'),
    PARTNER_AND_TENANT: t('authoring.partnerValueMap.partnerAndTenant'),
  };
  const privacyCollaborators = watch('privacyCollaborators') || [];
  const contentType = watch('contentType');

  const segmentedFieldCount =
    locations.length +
    departments.length +
    teams.length +
    orgLevels.length +
    grades.length +
    groups.length;

  const allSegmentedFields = [
    ...locations,
    ...departments,
    ...teams,
    ...orgLevels,
    ...grades,
    ...groups,
  ].map((field) => field.name);

  const { data } = useQuery({
    queryKey: [
      'segmentation-user-count',
      locationIds,
      departmentIds,
      gradeIds,
      orgLevelIds,
      teamIds,
      groupIds,
    ],
    queryFn: () =>
      SegmentationService.getSegmentedUserCount(
        locationIds,
        departmentIds,
        teamIds,
        orgLevelIds,
        gradeIds,
        groupIds,
      ),
  });

  return (
    <Grid templateColumns="repeat(3, 1fr)">
      <GridItem>
        <Text variant="createLabel">
          {contentType === ContentTypes.Assessment
            ? t('authoring.assessment.adminSettings')
            : t('authoring.publishPreview.audienceSegmentation')}
        </Text>
      </GridItem>

      <GridItem>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Text variant="createLabel">
              {contentType === ContentTypes.Assessment
                ? t('authoring.assessment.audience.segmentation')
                : t('authoring.publishPreview.segmentation')}
            </Text>

            {contentType === ContentTypes.Assessment && (
              <Text variant="createHelpText">
                {segmentedFieldCount > 0 ? allSegmentedFields.join(',') : 'None'}
              </Text>
            )}

            {data && contentType !== ContentTypes.Assessment && (
              <Text variant="createHelpText">
                {data} users{segmentedFieldCount > 0 ? ':' : ''}
                {locations.length > 0 && (
                  <Text variant="createHelpText">
                    {locations.map((location) => location.name).join(', ')}
                  </Text>
                )}
                {departments.length > 0 && (
                  <Text variant="createHelpText">
                    {departments.map((department) => department.name).join(', ')}
                  </Text>
                )}
                {teams.length > 0 && (
                  <Text variant="createHelpText">{teams.map((team) => team.name).join(', ')}</Text>
                )}
                {orgLevels.length > 0 && (
                  <Text variant="createHelpText">
                    {orgLevels.map((orgLevel) => orgLevel.name).join(', ')}
                  </Text>
                )}
                {grades.length > 0 && (
                  <Text variant="createHelpText">
                    {grades.map((grade) => grade.name).join(', ')}
                  </Text>
                )}
                {groups.length > 0 && (
                  <Text variant="createHelpText">
                    {groups.map((group) => group.name).join(', ')}
                  </Text>
                )}
              </Text>
            )}
          </Stack>

          {contentType === ContentTypes.Assessment && (
            <Stack spacing={2}>
              <Text variant="createLabel">{t('assessment.publishPreview.contributors')}</Text>
              <Text variant="createHelpText">{privacyCollaborators.length || 0}</Text>
            </Stack>
          )}

          <Stack spacing={2}>
            <Text variant="createLabel">{t('authoring.publishPreview.extend')}</Text>

            <Text variant="createHelpText">{partnerValueMap[partnerSelection!]}</Text>
          </Stack>
        </Stack>
      </GridItem>

      <GridItem display="flex" justifyContent="end" alignItems="center">
        <EditSectionButton
          onEdit={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            onEdit();
          }}
        />
      </GridItem>
    </Grid>
  );
}
