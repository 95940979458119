import { useQuery } from '@tanstack/react-query';
import ProductsService from '@/client/services/api/graphql/ProductsService';

export const useGetPurchaseFromProduct = (userId: string, productId: string) => useQuery({
  enabled: !!userId && !!productId,
  queryKey: ['dashboard-purchases', userId, productId],
  queryFn: () =>
    ProductsService.listPurchases({
      user: { eq: userId },
      product: { eq: productId },
      subscriptionActive: true,
      page: 0,
      pageSize: 1,
      includeTotalCount: false,
    }),
});

export const useCheckIfProductIsActive = (userId: string, productId: string, refetchInterval?: number) => useQuery({
  enabled: !!userId && !!productId,
  queryKey: ['check-if-active', userId, productId],
  queryFn: () => 
    ProductsService.listPurchases({
      user: { eq: userId },
      product: { eq: productId },
      subscriptionActive: true,
      page: 0,
      pageSize: 1,
      includeTotalCount: false,
    }),
  select: (data) => data.purchases.length > 0,
  refetchInterval,
  refetchIntervalInBackground: !!refetchInterval
});